%topic-card {
    display: flex;
    transition: box-shadow 0.2s;
}

.topic__card {
    @extend %topic-card;
    height: toRem(188);
    align-items: flex-end;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    @include media('>phone') {
        height: toRem(218);
    }

    @include media('>tablet') {
        height: toRem(165);
    }

    &:hover {
        box-shadow: $card-box-shadow;
    }

    &__text {
        width: 100%;
        margin: 0;
        padding: toRem(8);
        color: $lighter;
        background: linear-gradient(to right, $journal-color 0%, transparent 140%);
        font-size: 0.875rem;
        font-weight: bold;

        .journal-branding & {
            /*stylelint-disable declaration-block-no-duplicate-properties*/
            background: transparent;
            background: linear-gradient(to right, themed('journal') 0%, transparent 140%);
            /*stylelint-enable declaration-block-no-duplicate-properties*/
        }
    }
}

.featured-topic__card {
    @extend %topic-card;
    align-items: center;
    box-shadow: $card-box-shadow;
    border-radius: toRem(8);
    padding: toRem(16);
    background: $light-gray;

    &__img {
        height: toRem(40);
        border-radius: toRem(4);
        margin-right: toRem(8);
        border: toRem(1) solid $journal-color;
        overflow: hidden;
        flex: 0 0 toRem(40);
        @include journal-branding('border-color');
    }

    &__text {
        font-size: 0.875rem;
        font-weight: bold;
        color: $journal-color;
        @include journal-branding('color');
    }

    &:hover {
        box-shadow: $card-box-shadow-hovered;
    }
}
