$light: #fafafa;
$lighter: #fff;
$primary: $lighter;
$secondary: rgb(0, 128, 102);
$light-gray: #f0f0f0;
$dark-gray: #707070;
$darker-gray: #505050;
$dark: #575a5d;
$dark-secondary: #333;
$darker: #000;
$body-bg: $lighter;
$body-dark-bg: $light;
$darker-link-color: #3e63b4;
$btn-link-color: $darker-link-color;
$danger: #ec2020;
$red: #f00;
$royal-purple: #64419f;

$journal-color: $secondary;
$aim: rgb(0, 115, 119);
$acr: rgb(95, 37, 159);
$ajc: rgb(13, 46, 139);
$aimcc: rgb(95, 36, 159);

//----Branding
$link-secondary-color: $secondary;
$main-nav-link-hover-color: $secondary;
//-------inputs
$input-bg: $light;
//-------Header
$header-color: $dark;
$header-quick-menu-color: $secondary;
$menu-text-color: $dark;
$quick-search-menu-border: rgba($dark, 0.1);
$journal-menu-border-color: rgba($lighter, 0.2);
$menu-dark-bg-color: $dark;
//-------Footer
$footer-bg: $lighter;
$footer-text-color: $darker;
$footer-heading-color: $darker;
$footer-hover-color: $darker;
$footer-secondary: $light-gray;
$back-to-top-bg: $dark-secondary;
//-------issue-items
$issue-item__authors-color: $dark-gray;
$pdf-icon-color: #eb5757;
//-------audio player
$audio-player-bg-color: $light-gray;
$audio-player-controls-color: $darker;
$audio-player-time-tracker-color: $dark-secondary;
//-------current issue
$current-issue-title-bg-color: #ffc82e;
//-------cards shadow
$card-box-shadow: 0 toRem(2) toRem(6) rgba($darker, 0.2);
$card-box-shadow-hovered: 0 toRem(4) toRem(6) rgba($darker, 0.2);
$card-box-shadow--elevated: 0 toRem(4) toRem(44) rgba(0, 0, 0, 0.1);
$card-citation-color: $darker-gray;
//------loi
$loi-wrapper-bg: $light-gray;
//------wslide
$slide-head-bg: $darker;
//------search
$search-filter-bg: $darker;
$applied-facets-bg: $light-gray;
//------article
$section-nav-bg: $dark-secondary;
$section-nav-article-separator-color: rgba(255, 255, 255, 0.2);
$section-nav-actionbar-bg: $body-dark-bg;
//------commerce
$commerce-link-color: #757575;
//------topical index
$topical-header-color: $journal-color;
$topical-text-color: $journal-color;
$topical-count-color: $dark;
$topical-prefix-bg: $light-gray;
$topical-prefix-color: $dark-secondary;
//------activities
$claim-section-title-color: $dark-secondary;

//------Exams
$exam-text-color: $journal-color;
$exam-count-color: $dark;
$exam-title-color: $journal-color;
$exam-link-color: $journal-color;
$exam-meta-color: $dark-secondary;
$exam-border-color: $dark-secondary;
$exam-link-bg-color: $lighter;
$claim-credit-info-border-color: #d9d9d9;

$success-radio: #66a22b;

$profile-tabs-bg: $lighter;
$profile-tabs-active-bg: $lighter;
$profile-tabs-color: $dark-secondary;
$profile-tabs-active-color: $journal-color;
$rounded-tab-border-radius: 0;

$open-access-color: #f68212;

//------icon-fonts
$icon-PDF-color: $red;

// publication-content
$correction-authors-color: $dark-gray;
$clinical-reflections-link-color: #0031ca;
$clinical-reflections-bg-color: #dfdfdf;

// exam-agreement
$exam-agreement-section-color: $dark;

// show-exam
$show-exam-message-fail-color: $danger;

//Advanced Seach
$autoComplete-list-item-text-color: #666;
$advanced-search-timeframe-border: toRem(1) solid #d9d9d9;

$transparent-color: #fff0;

$openAthens-bottom-border: toRem(1) solid #d9d9d9;
