.show-exam {
    a {
        color: $secondary;
        @include journal-branding('color');
    }

    .section__header {
        font-size: 1rem;
        border-bottom: toRem(1) solid $border-color;
        margin-bottom: toRem(8);
        padding-bottom: toRem(8);
    }

    &__title {
        font-size: 1rem;
    }

    &__article {
        font-weight: 500;
        border-bottom: toRem(1) solid $border-color;
        padding-bottom: 0;

        &-title {
            margin-bottom: toRem(8);
            font-size: 1.375rem;
            font-weight: inherit;
            @include media('<tablet') {
                font-size: 1.125rem;
            }
        }

        &-authors {
            font-size: 0.75rem;
            color: $dark-gray;
            padding-bottom: toRem(8);

            a {
                color: inherit;

                .journal-branding & {
                    color: inherit;
                }
            }
        }
    }

    &__message {
        border: none;
        padding: 0;
        font-weight: 500;
        margin-top: 1rem;
        font-size: 0.75rem;

        i {
            margin-right: toRem(12);
            font-size: 0.875rem;
        }

        &-success,
        &-fail {
            border: toRem(2) solid;
            padding: toRem(20);
            display: flex;

            i {
                color: $show-exam-message-fail-color;
            }

            span {
                padding-left: 0;
            }
        }

        &-success {
            border-color: $aim;

            i {
                color: $success;
            }
        }

        &-fail {
            border-color: $show-exam-message-fail-color;
            color: $show-exam-message-fail-color;
        }
    }

    &__questions {
        font-size: 0.875rem;

        &-item {
            margin-top: toRem(24);
            padding-bottom: toRem(16);
            border-bottom: toRem(1) solid $border-color;
            font-size: 0.875rem;
            ol {
                list-style: none;
            }

            h6 {
                font-size: inherit;

                .journal-branding & a {
                    color: $secondary;
                }

                p {
                    &:first-child {
                        display: inline;
                    }
                }
            }

            li:before {
                border-color: $secondary;
                @include journal-branding('border-color');
            }
        }

        li {
            color: $darker;

            a {
                margin-left: toRem(4);
                text-decoration: underline;
                font-weight: bold;
            }

            &.show-exam__questions-item-correct {
                background-color: $light-gray;

                .label-txt {
                    padding-left: 0;
                    &:after {
                        content: none;
                    }
                }

                &:before {
                    content: '\e5ca';
                    background-color: $success;
                    border-color: $success;
                }
            }

            &.show-exam__questions-item-incorrect {
                background-color: $light-gray;

                .label-txt {
                    padding-left: 0;
                    &:after {
                        content: none;
                    }
                }

                &:before {
                    content: '\e5cd';
                    background-color: transparent;
                    color: $danger;
                    border-color: $danger;
                    font-size: 0.5rem;
                    padding: 0;
                }
            }
        }

        .btn {
            margin-top: toRem(32);
            @include media('<tablet') {
                width: 100%;
            }
        }

        &-form {
            button {
                width: toRem(200);
            }
        }
    }

    .label-txt {
        font-size: 0.875rem;
        display: inline-block;
        padding-left: toRem(28);

        &:before {
            flex: 0 0 auto;
        }
    }

    .radio--primary,
    .checkbox--primary {
        span:before {
            left: 0;
            position: absolute;
            border-color: $secondary;
            @include journal-branding('border-color');
        }
    }

    .radio--primary {
        input {
            ~ span:after {
                top: toRem(5);
                left: toRem(5);
            }
            &:checked ~ span {
                &::after {
                    height: toRem(10);
                    width: toRem(10);
                }
            }
        }
    }

    &__explanation {
        display: block;
        margin-top: toRem(2);
        padding-top: toRem(2);
        padding-left: toRem(38);
        margin-left: toRem(-38);
        background-color: $lighter;
        position: relative;
        font-size: 0.75rem;
        color: $dark;
        line-height: toRem(20);

        p:first-child {
            margin-top: 0;
            padding-top: 0;
        }

        a {
            text-underline: none;
            font-weight: inherit;
        }

        &:before {
            content: '';
            display: block;
            background-color: $secondary;
            @include journal-branding('background-color');
            position: absolute;
            left: toRem(30);
            top: toRem(4);
            bottom: 0;
            width: toRem(3);
        }
    }

    &__box {
        padding: toRem(16) toRem(16) toRem(22);
        border-color: $secondary;
        @include journal-branding('border-color');

        .title {
            font-size: 1.25rem;
            margin-bottom: 0;
            color: $secondary;
            @include journal-branding('color');
        }

        &-link {
            margin-top: 1rem;
            font-size: 0.75rem;
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }

        p {
            color: $darker;
            font-size: 0.875rem;
            margin: 1rem 0;
            padding: 0;
        }
    }

    &__modal {
        .modal {
            &__dialog {
                width: auto;
                max-width: toRem(800);
            }
        }
    }

    &__credit {
        margin-bottom: 0;

        &-options {
            border: toRem(1) solid $border-color;
            border-left: 0;
            border-right: 0;
            padding: toRem(16) 0 toRem(24);

            &-info {
                color: $secondary;
                @include journal-branding('color');
            }

            input {
                text-align: center;
                background-color: $lighter;
                border-radius: toRem(3);
                color: $secondary;
                @include journal-branding('color');
                width: toRem(144);
                font-size: 0.75rem;
                padding: toRem(10) toRem(20);
                border: toRem(1) solid $border-color;
            }

            .row {
                display: flex;
                flex-wrap: wrap;
            }

            .col-md-12 {
                flex: 1;
            }

            .col-sm-6 {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media('<tablet') {
                    &:not(:first-child) {
                        margin-top: toRem(24);
                    }
                }

                h6 {
                    color: $dark-secondary;
                    font-size: 0.875rem;
                }

                p {
                    margin: 0 0 toRem(4);
                    font-weight: 500;
                    font-size: 0.75rem;
                }
            }
        }

        .btn--full {
            padding-top: toRem(10);
            padding-bottom: toRem(10);
            width: 100%;
            color: $secondary;
            border-color: $secondary;
            @include journal-branding('color');
            margin-top: toRem(24);
            @include media('<tablet') {
                margin-top: 0;
            }

            &:hover,
            &:active,
            &:focus {
                color: $white;
            }
        }
    }

    &__note {
        text-align: center;
        margin: toRem(16) 0 toRem(24);
        padding: toRem(8);
        background-color: $light-gray;
        color: $secondary;
        @include journal-branding('color');
        font-weight: bold;
        font-size: 0.75rem;
    }
}
