.exam-list {
    &__itemsPrefix,
    &__list-item {
        font-size: 0.875rem;

        span {
            flex: 1;
        }
    }

    &__by-topic {
        .exam-list__itemsPrefix {
            span:first-child {
                flex: 3;
            }
        }

        .exam-list__list-item {
            border: none;
            padding: 0 toRem(16);
        }
    }

    &__itemsPrefix {
        @extend .topical-index__itemsPrefix;
    }

    &__list-item {
        display: flex;
        @extend .topical-index__item;
        border-top: toRem(1) solid $borders;
        padding: toRem(12) toRem(16);
    }

    .expandable-list {
        &__toggle {
            &__icon {
                font-weight: 800;
                display: inline-block;
                padding-right: toRem(10);
                min-width: toRem(23);
                font-size: 1rem;
            }
        }

        &__body {
            padding-left: toRem(42);
        }
    }

    &__item {
        width: 100%;
    }

    &__by-year {
        @include journal-branding('color');

        li {
            position: relative;
        }
    }

    &__list-item__count__wrapper {
        .expandable-list__body {
            margin-bottom: toRem(6);
            line-height: toRem(18);
            margin-top: toRem(-8);
        }
    }

    &__list-item__count__control {
        position: absolute;
        right: toRem(16);
        top: toRem(13);
        padding: 0;
        margin: 0;
        border: none;
        color: $topical-prefix-color;
    }
}

[class*='exam-list__list-item__count-'] {
    color: $exam-count-color;

    @include media('>=phone') {
        text-align: center;
    }

    @include media('<phone') {
        margin: 0;
        font-size: 0.75rem;
    }

    span.label {
        font-weight: 500;
        color: $topical-prefix-color;
        vertical-align: baseline;
        margin-right: toRem(6);
    }
}
