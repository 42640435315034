.multimedia-item {
    border-bottom: toRem(1) solid $border-color;
    padding: toRem(16) 0 toRem(18);
    display: flex;
    align-items: flex-start;

    &__image {
        overflow: hidden;
        height: toRem(65);
        width: toRem(65);
        flex: 0 0 toRem(65);
        border-radius: toRem(2);
        margin-right: toRem(16);
        @include media('>phone') {
            margin-right: toRem(24);
        }
    }

    &__title {
        margin-bottom: toRem(8);
    }

    &__content {
        font-size: 0.875rem;
        color: $dark-secondary;
    }
}

.multimedia-items {
    margin-bottom: toRem(24);
    @include media('>phone') {
        margin-bottom: toRem(32);
    }
}
