.topical-index {
    $this: &;
    padding: 0;

    color: $topical-text-color;
    @include journal-branding('color');
    font-size: 0.875rem;

    .expandable-list {
        &__toggle {
            font-weight: 500;

            .expandable-list__toggle__icon {
                font-size: 1rem;
                vertical-align: middle;
            }
        }

        &__body {
            padding-left: toRem(26);

            a {
                padding: toRem(6) toRem(16);
                border: none;
            }

            .expandable-list {
                .topical-index__label {
                    margin-right: toRem(-20);
                }
            }
        }
    }

    &__itemsPrefix,
    #{$this}__item {
        @include media('>=phone') {
            display: flex;
        }
    }

    &__itemsPrefix {
        @include media('>=phone') {
            text-align: center;
        }
        padding: toRem(10) toRem(16);
        background: $topical-prefix-bg;
        color: $topical-prefix-color;
        font-weight: 600;

        span:first-child {
            text-align: left;
            margin-right: toRem(26);
        }
    }

    #{$this}__item {
        font-size: 0.875rem;
        padding: toRem(12) 0;
    }

    span {
        flex: 1;
        @include media('<tablet') {
            display: inline-flex;
            align-items: center;
        }
    }

    &__list {
        overflow: hidden;

        > .expandable-list {
            > .expandable-list__toggle {
                margin-left: toRem(-26);
            }
        }

        > li {
            > #{$this}__item {
                padding: toRem(12) toRem(42);
                padding-right: toRem(16);
            }

            > .topical-index__count__wrapper {
                .expandable-list__body {
                    margin-top: toRem(-8);
                }

                .topical-index__count__control.topical-index__count__control {
                    top: toRem(14);
                }
            }
        }

        li {
            position: relative;
        }
    }

    .topical-index__count__control.topical-index__count__control {
        position: absolute;
        right: toRem(5);
        top: toRem(2);
        padding: 0;
        margin: 0;
        border: none;
        color: $topical-prefix-color;
    }

    .topical-index__count__wrapper .expandable-list__body {
        margin-bottom: toRem(6);
        line-height: toRem(18);
    }

    &__years__list {
        a {
            font-size: 0.875rem;
        }

        .exam {
            &-list__itemsPrefix {
                display: none;
            }

            &-item {
                &__link {
                    a {
                        border: toRem(1) solid #d4d4d4;
                        padding: toRem(8) toRem(24);
                        font-size: toRem(12);
                        display: inline-flex;
                    }
                }

                &__content {
                    flex: 1;
                }

                &__credit-hours span {
                    @include media('<tablet') {
                        margin-left: toRem(4);
                    }
                }

                span.exam-item__status {
                    @include media('<tablet') {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

[class*='topical-index__count-'] {
    @include media('>=phone') {
        text-align: center;
    }
    color: $topical-count-color;

    @include media('<phone') {
        margin: 0;
        margin-left: toRem(16);
        font-size: 0.75rem;
    }

    span.label {
        font-weight: 500;
        color: $topical-prefix-color;
        vertical-align: baseline;
    }
}
