.claim-credit__info {
    @include media('>=tablet') {
        margin-top: toRem(32);
    }

    &__text {
        font-size: 0.875rem;
    }

    padding-bottom: toRem(24);
    border-bottom: toRem(1) solid $claim-credit-info-border-color;
}
