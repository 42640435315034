.top-content {
    $this: &;
    display: flex;
    flex-wrap: wrap;

    .teaser__item {
        display: block;
        width: 100%;
        border-bottom: toRem(1) solid $border-color;
        padding-top: toRem(24);
        margin-bottom: 0;

        &.no-border {
            border-bottom-color: transparent;
        }
    }

    .issue-item {
        margin-bottom: 0;

        &__authors {
            color: $dark-gray;
            font-weight: 500;

            [class*='read'] {
                color: $dark;
            }
        }
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: toRem(24);
    }

    &--aside {
        margin: 2rem 0 1.5rem;

        .issue-item {
            h5 {
                font-size: 0.875rem;
            }
        }

        .title {
            margin-bottom: toRem(-8);
            color: $journal-color;
            @include journal-branding('color');
        }

        .teaser__item {
            @include media('>phone', '<desktop') {
                width: calc(50% - #{toRem(16)});

                &:nth-child(odd) {
                    margin-right: toRem(16);
                }
                &:nth-child(even) {
                    margin-left: toRem(16);
                }
            }
        }
    }

    &--gird {
        display: flex;
        flex-wrap: wrap;

        @include media('>phone') {
            columns: 2;
        }
        @include media('>tablet') {
            column-gap: toRem(46);
        }

        .teaser__item {
            width: 100%;
            @include media('>=tablet') {
                width: calc(50% - #{toRem(32)});
                &:nth-child(odd) {
                    margin-left: toRem(16);
                }

                &:nth-child(even) {
                    margin-right: toRem(16);
                }
            }

            &.no-border {
                @include media('<tablet') {
                    &:not(:last-child) {
                        border-bottom: toRem(1) solid $border-color;
                    }
                }
            }
        }

        + #{$this}__action {
            padding-top: toRem(16);
            border-top: toRem(1) solid $border-color;
        }
    }

    &--landing {
        .teaser__item {
            padding-top: toRem(14);

            &:first-child {
                padding-top: 0;
            }
        }

        .issue-item {
            padding-bottom: toRem(16);
        }
    }
}
