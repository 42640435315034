.loi {
    .title {
        font-size: 0.875rem;
        font-weight: 600;
        color: $dark-secondary;
        line-height: toRem(20);
        margin: toRem(8) 0;
        padding-right: toRem(16);
        width: 90%;
    }
    .scroll {
        direction: ltr;
    }
}
