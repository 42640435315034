.exam-certificate-message {
    $this: &;
    padding: toRem(32) toRem(24);
    border: toRem(1) solid $secondary;
    @include journal-branding('border-color');
    color: $darker;
    font-size: 0.875rem;
    line-height: toRem(18);

    &__top-info {
        border-bottom: toRem(1) solid $border-color;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;

        h2 {
            margin-bottom: 1rem;
        }

        p {
            font-size: 0.875rem;
            font-weight: 500;
        }
    }

    &__heading {
        font-size: $h6-font-size;
        color: $dark-secondary;
        padding: 0;
        line-height: toRem(18);
        margin: 1.5rem 0 1rem;
        @include media('>phone') {
            margin-top: 1rem;
        }
    }

    &__subheading {
        padding: 0 1.5rem;
    }

    &__name {
        font-size: $h2-font-size;
        color: $dark-secondary;
        font-weight: bold;
        line-height: toRem(32);
        margin: 0 2rem;
    }

    &__text {
        padding: 0;
    }

    &__title {
        border: toRem(1) solid $border-color;
        padding: 1.5rem 0.5rem;
        color: $dark-secondary;
        font-size: $h5-font-size;
        font-weight: 500;
        line-height: toRem(28);
        margin: 1rem 0;

        @include media('>phone') {
            font-size: $h4-font-size;
        }
    }

    &__credit {
        padding: 0;
        margin: 1.5rem 2rem;
    }
    &__date {
        color: $darker;
        padding: 0 2rem;
    }

    &__info {
        margin: 0.25rem 1rem;
        font-size: 0.625rem;

        + #{$this}__text {
            margin: 2rem 0;
            p {
                margin: 0.25rem 0;
            }
        }
    }

    &__link {
        padding: toRem(8) toRem(24);
    }
}
