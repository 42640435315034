.main-nav {
    $this: &;

    &__right-menu {
        color: $link-secondary-color;
        @include journal-branding('color');

        li {
            &:last-child {
                a {
                    border-bottom: none;
                }
            }
        }
    }

    .drawer__nav {
        .menu-item {
            &:hover {
                color: $link-secondary-color;
                @include journal-branding('color');
            }
        }
    }
    &.menu--res {
        button[data-toggle='nav'],
        a[data-toggle='nav'],
        .menu-header {
            top: toRem(16);

            @include media('>phone') {
                top: toRem(26);
            }
        }

        .menu-header {
            background-color: $secondary;
            @include journal-branding('background');
            color: $lighter;
            height: toRem(56);
            top: toRem(32);
            padding-left: 1rem;
            @include media('>phone') {
                top: toRem(54);
            }
        }

        a[data-toggle='nav'] {
            &.js--open {
                right: 0;
                height: toRem(56);
                top: toRem(32);
                @include media('>phone') {
                    top: toRem(54);
                }
                background-color: $secondary;
                border-left: toRem(1) solid $journal-menu-border-color;
                @include journal-branding('background');
            }
        }

        .drawer__nav {
            box-shadow: none;
            padding-bottom: toRem(16);
            height: calc(100vh - #{toRem(144)}); // menu-header-height + menu-header-top + journal-menu-height
            @include media('>phone') {
                height: calc(100vh - #{toRem(166)});
            }
        }

        .js--open {
            span {
                &:before,
                &:after {
                    background-color: $lighter;
                }
            }
        }

        #{$this}__right-menu {
            a {
                color: $secondary;
                @include journal-branding('color');
            }
        }
    }

    .journal-menu {
        display: none;
        position: fixed;
        width: toRem(320);
        top: calc(100vh - 50%);
        height: toRem(56);

        @include media('<tablet') {
            top: calc(100vh - 50% - #{toRem(12)});
        }

        &__right {
            display: flex;
            flex-direction: row-reverse;
            height: 100%;
            padding: 0;
        }

        a {
            padding: 0;
        }

        .institution {
            display: flex;
            border-right: 0;
            align-items: center;
            width: 100%;
            flex-direction: row;
            padding: 0 toRem(8);
            a {
                flex: 1;
                overflow: hidden;
                &:hover {
                    color: $lighter;
                }
            }

            &__image {
                padding-right: toRem(8);
                margin-right: toRem(8);
                border-right: toRem(1) solid $journal-menu-border-color;
                border-left: none;
                padding-left: 0;
                margin-left: 0;
            }

            &-info-wrapper {
                display: block;
            }

            &__intro {
                display: block;
                font-size: 0.625rem;
                font-weight: normal;
            }

            &__name {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .transplanted-clone {
            width: 100%;
        }

        @at-root #{$this}.js--open .journal-menu {
            display: flex;
        }
    }
}

.main-menu {
    &__icon {
        background-color: $link-secondary-color;
        @include journal-branding('background-color');

        &:before,
        &:after {
            background-color: inherit;
        }
    }
}

.menu-header {
    &.hidden-xl {
        @media screen and (min-width: toRem(1200)) {
            display: none;
        }
    }
}
