.toc-container {
    @include media('<tablet') {
        margin-top: toRem(24);
    }
}

.table-of-content {
    columns: 1;
    column-gap: toRem(30);
    margin-bottom: toRem(24);
    padding-bottom: toRem(6);
    border-bottom: toRem(1) solid $border-color;

    @include media('>phone') {
        columns: 2;
        padding-bottom: toRem(16);
    }

    @include media('>tablet') {
        margin-top: toRem(16);
    }

    &__wrapper {
        padding: 0 toRem(16);
    }
    .issue__item__abstract {
        display: none;
    }
}

.titled_issues {
    break-inside: avoid;
    page-break-inside: avoid;
    box-sizing: border-box;
    display: block;
    width: 100%;

    &__title {
        text-transform: uppercase;
        color: $journal-color;
        border-bottom: toRem(4) solid $journal-color;
        padding: toRem(4) 0;
        display: flex;
        align-items: center;
        gap: toRem(8);
        font-size: toRem(18);
        @include journal-branding(
            'color',
            (
                'border-color': var(--journal-color),
            )
        );
    }
}
.hide_text {
    &:after {
        content: '\e313';
        float: right;
        font-family: icomoon !important;
        font-size: toRem(12);
        padding-right: toRem(10);
    }
    &.slid-up {
        &:after {
            content: '\e316';
        }
    }
}
