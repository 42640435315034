.page-top-banner {
    min-height: auto;

    .advertisment {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: toRem(16) toRem(16) toRem(14);
        img,
        iframe {
            max-width: 100%;
        }
    }

    @include media('>=desktop') {
        min-height: toRem(60);
    }

    .page__title {
        margin: toRem(10) toRem(75);
        @include media('<tablet') {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--no-shift-after {
        .page__title {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--no-title-margin {
        .page__title {
            margin: 0;
        }
    }

    &--extra-height {
        min-height: toRem(78);

        @include media('>phone') {
            min-height: toRem(90);
        }

        .page__title {
            margin: toRem(8) 0 toRem(46);

            @include media('>phone') {
                margin: toRem(12) 0 toRem(60);
            }
        }
    }
}
