.header {
    padding: 0;
    box-shadow: 0 toRem(4) toRem(4) rgba($shadow-color, 0.1);

    &--first-row {
        padding: 0 toRem(12);
        height: toRem(56);
        @include media('>=large') {
            height: toRem(57);
            margin: 0 toRem(24);
            padding: 0;
            border-bottom: toRem(1) solid $border-color;
        }
        @media screen and (min-width: toRem(1200)) {
            height: toRem(90);
        }
    }

    &--second-col {
        @include media('<large') {
            flex: 0 1;
        }
    }

    &--second-row {
        padding: 0 toRem(16);
        @include media('<large') {
            height: 0;
        }
        @media screen and (max-width: toRem(1199)) {
            height: 0;
        }
    }

    &__logo {
        width: toRem(174);

        @include media('>=desktop') {
            width: toRem(360);
        }

        .aim & {
            width: toRem(444);

            @include media('<desktop') {
                width: toRem(314);
            }

            @include media('<tablet') {
                width: toRem(100);
            }
        }

        .acr & {
            width: toRem(162);

            @include media('>=desktop') {
                width: toRem(234);
            }
        }

        .ajc & {
            width: toRem(158);

            @include media('>=desktop') {
                width: toRem(308);
            }
        }

        img {
            max-height: 100%;

            @include media('<desktop') {
                width: 100%;
            }
        }

        &__info {
            font-size: 0.625rem;
            font-style: italic;
            font-weight: 500;
            color: $darker;
            padding: toRem(8) toRem(16);
            margin-left: toRem(16);
            border-left: toRem(1) solid $border-color;
            display: none;
            @include media('>=desktop') {
                width: toRem(194);
            }
            @include media('>=tablet') {
                width: toRem(294);
                display: block;
            }
        }
    }

    &__quick-menu {
        @media screen and (max-width: toRem(1200)) {
            padding-right: toRem(42);
        }
        @include media('<large') {
            li {
                border-left: toRem(1) solid $quick-search-menu-border;
                border-right: toRem(1) solid $quick-search-menu-border;
                padding: toRem(8) 0;
            }
        }

        @include media('<tablet') {
            padding-right: toRem(32);
            .lock-screen & {
                padding-right: toRem(42);
            }
        }

        a {
            color: $link-secondary-color;
            @include journal-branding('color');
        }

        .show-login {
            margin-right: 0;
            @include media('<large') {
                padding: 0 toRem(12) 0 toRem(12);
            }
        }
        .sign-in {
            @media screen and (max-width: toRem(1200)) {
                display: none;
            }
        }
    }
    .quick-search--advanced {
        @include media('<=large') {
            margin-right: 0;
        }
    }
}

.journal-menu {
    $height: toRem(32);
    $this: &;
    background-color: $dark;
    height: $height;
    display: flex;
    align-content: center;
    justify-content: space-between;

    .site__logo {
        width: toRem(50);
        margin-left: toRem(8);

        a {
            display: flex;
        }
    }

    &__left,
    &__right {
        align-items: center;
    }

    &__left {
        display: flex;
    }

    &__right {
        padding-right: toRem(8);
        display: none;
        @include media('>phone') {
            display: flex;
        }
        .institution {
            a {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }

    &__journals {
        display: flex;
        align-items: center;
        border-right: toRem(1) solid $journal-menu-border-color;

        #{$this}__dropblock {
            background-color: transparent;
            padding: 0;
        }

        .site__logo + & {
            border-left: toRem(1) solid $journal-menu-border-color;
            margin: 0 toRem(8);
        }
    }

    .institution {
        color: $lighter;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;

        &__image {
            border-left: toRem(1) solid $journal-menu-border-color;
            height: 100%;
            margin-left: toRem(8);
            padding-left: toRem(8);

            .header & {
                padding-right: 0;
                display: block;
            }
        }

        .journal-branding & {
            a {
                &:hover,
                &:focus {
                    color: $lighter;
                    text-decoration: underline;
                }
            }
        }

        @include media('>phone') {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }

        &-info-wrapper {
            text-transform: none;
        }

        a:hover {
            color: $lighter;
            text-decoration: underline;
        }
    }

    @include media('>phone') {
        $height: toRem(54);
        height: $height;
        .dropBlock__link {
            height: $height;
        }
    }

    .dropBlock__link {
        padding: 0 toRem(8);
        color: $lighter;
        height: $height;
        font-size: 0.75rem;

        &.js--open {
            color: $lighter;
            box-shadow: none;
        }

        svg {
            transition: all 0.5s;
            fill: $lighter;
        }

        &[aria-expanded='true'] {
            background-color: $dark-secondary;

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .dropblock--tab {
        width: toRem(290);
        background-color: $dark-secondary;
        padding: toRem(8) toRem(16);
        font-weight: 600;

        li {
            display: block;
            padding: 0;
            margin: 0;

            &.active {
                display: none;
            }
        }

        a {
            color: $lighter;
            padding: toRem(8) 0;
            border-bottom: none;
            font-size: 0.875rem;

            &:hover {
                color: $lighter;
                text-decoration: underline;
            }
        }
    }
}

.user-login-bar {
    .loginBar__username {
        @include media('<desktop') {
            margin-right: 0;
            border: 0;
        }

        span {
            color: $dark;
            font-weight: bold;
        }

        .welcome-label {
            text-align: left;
            display: block;
            font-weight: 500;
        }
    }
}
