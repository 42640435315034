.actionsbar {
    margin-bottom: toRem(24);
    @include media('<desktop') {
        background-color: $body-bg;
    }
    @include media('>=tablet', '<desktop') {
        top: $header-height-sm + toRem(16);
    }
    @include media('<tablet') {
        top: $header-height-sm;
    }

    .sections-navigation {
        @include media('>=#{$sections-break-point}') {
            &__menu {
                padding: 0 toRem(10);
                display: block;
                position: static;
                width: 100%;

                .sections-block {
                    &:empty + .sections-links {
                        border-top: none;
                    }
                }

                .sections-links {
                    border-top: toRem(1) solid $border-color;

                    a {
                        padding: toRem(10) toRem(2) toRem(0) toRem(9);
                    }

                    .clinicalReflection {
                        a {
                            color: $journal-color;
                            @include brand('color');
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        @include media('<#{$sections-break-point}') {
            border-radius: toRem(4);
            padding: 0 toRem(18);
            align-items: stretch;

            li:not(.article-navigation-items-separator) {
                padding: toRem(14) 0;
            }
            li:first-child {
                padding-right: toRem(16);
                border-right: toRem(1) solid $section-nav-article-separator-color;
            }
            a {
                padding: 0;
            }

            .article-navigation-items-separator {
                flex: 0 1 toRem(1);
                height: auto;
                display: none;
            }
        }

        @include media('<tablet') {
            padding: 0 toRem(29);
            align-items: stretch;

            li:not(.article-navigation-items-separator) {
                padding: toRem(14) 0;
                border: none;
            }
            .article-navigation-items-separator {
                display: block;
            }
        }

        &__menu {
            .sections-links {
                a {
                    font-size: 0.75rem;
                    display: block;
                    text-transform: uppercase;
                    font-weight: 600;

                    &:hover,
                    &:focus {
                        color: $hover-darker-color;
                    }
                }
            }
        }
    }

    .actions-block-container {
        padding: toRem(8) 0;
        @include media('<large') {
            background-color: $body-dark-bg;
        }
        @include media('<desktop') {
            background-color: $body-bg;
        }
        @include media('<tablet') {
            display: flex;
            flex-wrap: wrap;
        }

        & > div {
            display: flex;
            align-items: center;
            position: relative;
        }
    }
    .sections-block-container,
    .has-visual-abstract-btn {
        .js--open {
            + .js--open {
                top: toRem(36);
                @include media('<desktop') {
                    top: toRem(40);
                }
            }
        }
    }

    article & {
        @include media('<large') {
            background-color: $body-dark-bg;
        }
        @include media('<desktop') {
            background-color: $body-bg;
        }
    }

    .main-link,
    [data-db-target-for] {
        padding-top: 0;
        padding-bottom: 0;

        &:before {
            bottom: toRem(-2);
        }
    }

    .visual-abstract-btn {
        padding: toRem(8) toRem(16);
        margin-right: toRem(8);
        font-size: 0.75rem;
        display: none;

        + div {
            &:before {
                display: none;
            }
        }
        &.visible {
            display: flex;

            + div {
                &:before {
                    display: block;
                }
            }
        }
        i {
            color: $secondary;
            @include journal-branding('color');
            margin-right: toRem(6);
            font-size: 1rem;
        }
        span {
            font-size: inherit;
        }

        .journal-branding & {
            &:hover,
            &:active,
            &:focus {
                color: $lighter;
                i {
                    color: inherit;
                }
            }
        }

        @include media('<tablet') {
            width: 100%;
            margin-bottom: toRem(16);
            padding: toRem(10) toRem(16);
        }
    }

    .js--open {
        + .js--open {
            top: toRem(24);
            @include media('<desktop') {
                top: toRem(40);
            }
        }
    }
}

.sections-navigation {
    &__menu {
        @include media('<desktop') {
            > ul {
                li {
                    padding: toRem(16) 0;
                    border-top: toRem(1) solid $border-color;
                    display: block;
                    text-transform: uppercase;
                    font-weight: 600;
                    @include font-size(14);

                    .w-slide & {
                        padding: toRem(16);
                    }

                    a {
                        display: block;
                        padding: 0;
                    }
                }
            }
        }
    }
}

.actions-block-container {
    span {
        font-size: 0.875rem;
    }

    i {
        color: $dark;
        font-size: 1.25rem;
    }

    & > {
        div:before {
            color: $border-color;
        }
    }

    .main-link,
    .w-slide__btn {
        align-items: center;
        height: 100%;
        @include media('<tablet') {
            padding-left: 0;
            padding-right: 0;

            i {
                margin: 0;
            }
        }
    }

    @include media('<desktop') {
        background-color: $body-bg;
        border-bottom: toRem(1) solid $border-color;
        margin-bottom: 1rem;

        & > {
            div:before {
                color: $border-color;
                padding-right: toRem(16);
                padding-left: toRem(16);
            }
        }
    }
    @include media('>phone') {
        .main-link,
        .w-slide__btn {
            span {
                display: inline-flex;
            }
        }
    }
}
