.podcast {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: toRem(1) solid $border-color;
    border-bottom: toRem(1) solid $border-color;
    padding: toRem(16) 0;

    @include media('>phone') {
        flex-wrap: nowrap;
    }

    @include media('>tablet') {
        border-bottom: none;
        padding-bottom: 0;
    }

    &__img {
        display: flex;
        align-items: center;
        max-width: toRem(50);
        margin-right: toRem(14);

        @include media('>phone') {
            max-width: toRem(60);
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: bold;
        color: $journal-color;
        @include journal-branding('color');

        @include media('>phone') {
            font-size: 1rem;
        }
    }

    &__player {
        display: flex;
        flex: 1 0 100%;
        margin: 0;
        @include media('>phone') {
            flex: 1 auto;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-size: 0.75rem;
        font-weight: bold;
        color: $journal-color;
        @include journal-branding('color');
        text-transform: uppercase;

        a {
            display: flex;
            align-items: center;

            i {
                margin-right: toRem(6);
            }
        }

        a + a {
            &:before {
                content: ' ';
                background-color: $border-color;
                margin: 0 toRem(16);
                height: toRem(22);
                width: toRem(1);
                position: relative;
            }
        }
    }
}
