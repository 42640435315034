.article__access {
    padding: toRem(4);
    border-radius: toRem(2);
    border: toRem(1) solid $border-color;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: bold;
    vertical-align: middle;
    margin-left: toRem(8);
    span {
        margin: auto;
    }
}
