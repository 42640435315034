.current-issue {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: toRem(6);

    @include media('>phone') {
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 0;
    }

    .figure {
        margin: 0;
        padding: 0;
        border-bottom: 0;
        &__image {
            margin: 0;
        }
    }

    &__cover {
        flex: 0 1 auto;
        position: relative;
        margin-bottom: toRem(16);

        @include media('<tablet') {
            display: block;
        }

        img {
            border: toRem(4) solid $border-color;
            max-width: none;
            height: toRem(252);

            @include media('>phone') {
                height: toRem(196);
            }

            @include media('>tablet') {
                height: toRem(164);
            }
        }
    }

    &__title {
        z-index: map-get($z-indexes, 'current-issue-title');
        position: absolute;
        bottom: toRem(24);
        left: toRem(-8);
        padding: toRem(4) toRem(8);
        font-size: 0.625rem;
        margin-bottom: 0;
        background-color: $current-issue-title-bg-color;
        border-radius: toRem(4);
        color: $dark-secondary;
    }

    &__info {
        @include media('>phone') {
            padding: 0 0 0 toRem(24);
            display: flex;
            flex-direction: column;
        }
    }

    &__specifics {
        font-size: 1.375rem;
        margin-bottom: toRem(4);

        span {
            display: block;
            margin-top: toRem(8);
            @include media('>phone') {
                display: inline;
            }

            &:after {
                content: ' ';
                background-color: $dark-secondary;
                width: toRem(6);
                height: toRem(6);
                border-radius: 50%;
                display: inline-block;
                position: relative;
                top: toRem(-3);
            }
        }
    }

    &__details {
        font-weight: bold;
        text-align: center;
        padding: 0 toRem(8);
        @include media('>phone') {
            text-align: left;
        }
    }

    &__actions {
        .btn {
            vertical-align: middle;
            margin-bottom: toRem(16);
            margin-top: 0;

            @include media('>phone') {
                margin-bottom: 0;
            }

            &:hover {
                i {
                    color: $lighter;
                }
            }
        }

        [class*='icon'] {
            line-height: 0;
            margin-right: toRem(10);
            display: inline;
            vertical-align: middle;
            font-size: 1.125rem;
        }
    }

    &__description {
        margin-bottom: 1.5rem;

        a {
            color: $general-link-color;
            display: block;
            font-size: 1rem;
            text-decoration: underline;

            @include media('>phone') {
                display: inline;
                @include font-size(14);
            }
        }
    }
}
