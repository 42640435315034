.topics__collections {
    $this: &;
    .featured__topic + & {
        @include media('>phone') {
            margin-top: toRem(24);
        }
    }

    //&__title {
    //    color: $journal-color;
    //    @include journal-branding('color');
    //}

    &__content {
        .col-md-4 {
            margin-bottom: toRem(24);
        }
    }
    &--features {
        #{$this}__content {
            .col-md-4 {
                margin-bottom: toRem(16);
            }
        }
    }
}
