.purchaseArea {
    padding: toRem(24) toRem(24) toRem(38);

    h2 {
        color: $secondary;
        @include journal-branding('color');
        font-size: 1.125rem;
        margin-bottom: toRem(24);
    }

    &--list {
        padding: 0;
    }

    &--label {
        font-size: 0.875rem;
    }

    p {
        font-size: 0.875rem;
        @include set-font-family();
    }
}

.ecommerce {
    &__login {
        a {
            display: block;
            color: $commerce-link-color;
            font-weight: bold;
            font-size: 0.75rem;
            margin-bottom: toRem(16);
        }
    }

    section {
        &:not(:last-child) {
            padding-bottom: toRem(24);
            margin-bottom: toRem(24);
            border-bottom: toRem(1) solid $border-color;
        }
    }

    &__buttons {
        .btn {
            display: inline-flex;
            margin-right: toRem(16);
            font-size: 0.75rem;
            flex-direction: row;
            font-weight: bold;
            @include media('<tablet') {
                width: 100%;
                margin: 0 0 toRem(16);
                padding: toRem(12) toRem(24);
            }

            span {
                text-align: center;
            }
        }
    }
}
