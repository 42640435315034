%search-field-icon {
    i {
        line-height: 1.75;
        font-weight: inherit;
    }
}

.advanced-search {
    $this: &;

    &__tabs {
        .advanced-search {
            &__search-in {
                &__area {
                    padding: 0 toRem(28) 0 toRem(10);
                    margin-bottom: 0;
                }
            }

            &__search-term {
                @include media('>=tablet') {
                    width: 60%;
                }
                @include media('>=desktop') {
                    width: 70%;
                }
            }

            &__filters {
                border-bottom: $advanced-search-timeframe-border;
                margin-bottom: toRem(15);
                padding-bottom: toRem(15);
            }
        }
    }

    .input-group &__select {
        @include media('>=tablet') {
            width: 20%;
        }
    }

    &__search-in {
        &__area {
            color: $black;
        }
        &__term {
            & > select {
                background-color: $white;
            }
            & > input {
                background-color: $white;
            }
        }
        &__field.left-side-label {
            @include media('>=tablet') {
                flex-wrap: initial;
                label {
                    margin-top: 0;
                    width: 20%;
                }
            }
        }
    }

    &__filters {
        padding-bottom: 0;
        #{$this}__search-in__field + #{$this}__search-in__field {
            align-items: flex-start;
        }
        @include media('>=tablet') {
            border: 0;
            margin-bottom: 0;
        }
    }

    &__autocomplete {
        &__wrapper {
            @include media('>=phone') {
                width: 70%;
            }
            input {
                background-color: $white;
            }
        }
    }

    &__timeframe {
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: $advanced-search-timeframe-border;
        @include media('>=tablet') {
            margin-top: toRem(0);
            padding-top: toRem(8);
            & > label {
                width: 20%;
            }
        }

        .label-txt {
            text-transform: capitalize;
            line-height: 1;
        }

        &__item {
            .advanced-search__select {
                margin: 0;
            }
            select[disabled] {
                color: $dark-secondary;
            }
        }
        .timeframe {
            &__to {
                gap: toRem(20);
                @include media('>=tablet') {
                    margin-left: toRem(-32);
                }
                &__label {
                    margin: toRem(7) 0 toRem(15);
                    @include media('>=tablet') {
                        margin-right: toRem(10);
                    }
                }
                label {
                    min-width: toRem(35);
                    margin-right: 0;
                    @include media('<tablet') {
                        min-width: toRem(71);
                    }
                }
            }
            &__from {
                gap: toRem(20);
                label {
                    min-width: toRem(35);
                    @include media('<tablet') {
                        min-width: toRem(71);
                    }
                }
            }

            &__static-range,
            &__custom-range-filter {
                margin-inline-start: 0;
                @include media('>=tablet') {
                    margin-bottom: toRem(35);
                }
                .radio--primary {
                    @include media('>=tablet') {
                        width: toRem(130);
                    }
                }
                &__select {
                    width: toRem(145);
                }
            }

            &__custom-range-filter {
                margin-bottom: toRem(30);
                flex-direction: column;
                @include media('>=tablet') {
                    margin-bottom: toRem(45);
                }
                .radio--primary {
                    min-width: toRem(130);
                }
            }
        }
    }

    &-page {
        .card--shadow {
            box-shadow: $card-box-shadow--elevated;
        }
    }

    &__wrapper {
        @include media('<tablet') {
            box-shadow: none;
        }
    }
    &__default-published {
        @include media('>=tablet') {
            flex: 1;
        }
        &,
        &--item {
            display: flex;
        }
        label.checkbox--primary {
            width: auto;
            min-width: initial;
            input {
                @include media('>=tablet') {
                    width: auto;
                }
            }
        }
        @include media('<tablet') {
            flex-direction: column;
            label {
                margin-bottom: 0.75rem;
                width: 100%;
            }
        }
    }
}
