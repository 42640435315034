article {
    padding-top: 1.5rem;

    .advertisment {
        text-align: center;
    }

    @include media('>tablet') {
        padding-top: 2rem;
    }

    .history__section__title {
        @include set-font-family();
        line-height: toRem(22);
        font-size: 0.875rem;
        color: $darker;
        margin: 0;
    }

    .article {
        &__body {
            a:not(.btn),
            .ref {
                color: $general-link-color;
                font-weight: normal;
            }

            .hlFld-Abstract {
                .to-section {
                    @include media('<desktop') {
                        border-top: 0;
                        padding-top: 0;
                    }
                }

                .article-section__title.to-section {
                    margin-top: 0;
                }
            }

            .article-section__title.to-section {
                font-size: 1.5rem;
            }

            .clinical-reflections {
                &.article-section__title.to-section {
                    font-size: 1.75rem;
                    margin-top: 0;
                    padding-top: 0;
                    margin-bottom: 0;
                    border: none;
                    text-transform: uppercase;
                }
                &__section {
                    background: $clinical-reflections-bg-color;
                    padding: toRem(20) 2rem;
                    margin: toRem(20) 0;
                }

                &__content {
                    @include set-font-family($noto-serif-font);
                    color: $darker;
                    font-weight: 600;

                    &__link:not(.btn) {
                        // :not(.btn) is only used to override theme-level style
                        color: $journal-color;
                        @include brand('color');
                        font-weight: 600;
                        text-decoration: underline;
                    }
                }
            }
        }

        &-section__title {
            margin-top: toRem(24);
            padding-top: toRem(24);
            border-top: toRem(1) solid $border-color;
            margin-bottom: toRem(16);
        }
    }

    .article-table-content {
        font-size: 0.875rem;
        position: relative;
        background-color: $body-bg;
        .figure__caption {
            p {
                font-size: inherit;
                font-weight: bold;
            }
        }
        p {
            @include set-font-family();
            font-size: inherit;
            font-weight: normal;
        }

        .journal-branding & {
            border: toRem(1) solid $border-color;
            border-top: 0;
        }
        thead {
            background-color: $body-bg;
        }

        thead,
        tbody {
            position: relative;
            z-index: map-get($z-indexes, 'article-table-content-table-body');
            border-bottom: toRem(1) solid $secondary;
            @include journal-branding('border-color');
        }

        tbody {
            tr {
                &:nth-child(even) {
                    z-index: map-get($z-indexes, 'article-table-content-tbody-tr');
                    background-color: $body-bg;
                }
            }
        }

        .tableFooter {
            border: none;
            p {
                font-weight: normal;
            }
        }

        .table {
            z-index: map-get($z-indexes, 'article-table-content-table');
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $secondary;
                @include journal-branding('background-color');
                opacity: 0.05;
                z-index: map-get($map: $z-indexes, $key: 'article-table-content-bg');
            }

            caption {
                border-top: toRem(1) solid $secondary;
                border-bottom: toRem(1) solid $secondary;
                @include journal-branding('border-color');
                background-color: $body-bg;
                color: $dark-secondary;
            }
        }
    }

    .tab__content {
        .tab__pane {
            padding-top: 0;

            .section {
                margin-top: toRem(24);
                padding-top: toRem(24);
            }
        }
    }

    .loa {
        &-accordion {
            display: inline;
            white-space: pre-wrap;

            .ellipsis {
                margin-right: 0;
            }
        }

        &-wrapper {
            color: $issue-item__authors-color;

            @include media('>=phone') {
                p {
                    font-size: 0.875rem;
                    @include set-font-family();
                    font-weight: 500;
                }
                a {
                    font-size: 0.75rem;
                }
            }

            .bottom-info {
                p {
                    margin: 0;
                }

                a {
                    text-decoration-color: transparent;
                    font-weight: bold;
                    border: toRem(1) solid $border-color;
                    padding: toRem(6) toRem(12);
                    border-radius: $border-radius;
                    display: block;
                    text-align: center;
                    margin-bottom: toRem(8);

                    &:hover {
                        background-color: $secondary;
                        @include journal-branding(
                            'background-color',
                            (
                                'color': $lighter,
                            )
                        );
                    }
                }
            }
        }
    }

    .show-exam {
        color: $darker;
        margin-bottom: 1.5rem;

        p {
            @include set-font-family();
            line-height: toRem(18);
            margin-bottom: 0;
        }

        &__credit {
            padding-right: 2.75rem;

            &-info {
                border-top: toRem(1) solid $border-color;
                margin-top: 1rem;

                @include media('>tablet') {
                    padding-top: 0.5rem;
                }
            }
        }
    }

    .survey__info {
        margin-top: 1.5rem;
        border-bottom: toRem(4) solid $border-color;
        padding-bottom: 0.5rem;
        font-style: normal;
        font-size: 0.875rem;

        &__title {
            margin-bottom: 0.25rem;
        }
    }

    .supplementary-material {
        display: inline;
    }

    .quote {
        margin-bottom: 1rem;
    }

    .NLM_attrib {
        text-align: right;
        display: block;
    }

    .tableToggle .figure__image__wrapper {
        cursor: pointer;
    }
    .accordion-with-arrow {
        .accordion-tabbed {
            &__control {
                color: var(--journal-color);
                font-size: toRem(18);
                font-weight: 700;
            }
        }
    }
    h3.article-section__title {
        font-size: toRem(18);
    }
}

.anchor-spacer {
    position: relative;
    top: toRem(-235);
    visibility: hidden;
}

.article {
    &-page {
        padding-bottom: 3rem;
        @include media('>tablet') {
            background-color: $body-dark-bg;
        }

        .w-slide {
            .cover-details {
                margin-top: toRem(24);
            }
            .accordion-tabbed__tab {
                border-top: toRem(1) solid $border-color;
                padding: toRem(15);
            }
        }

        .permissions {
            i {
                font-size: 1rem;
            }
        }

        .section {
            p {
                font-size: 0.875rem;
                @include set-font-family();
                line-height: toRem(22);
                font-weight: 500;
                margin: 0;
            }

            .history__section__title {
                color: $darker;
            }
        }
    }

    &__inlineFigure {
        .figure__caption {
            font-size: 0.875rem;
            font-weight: normal;
            color: $dark-gray;
        }
    }

    &__references {
        .references {
            &__note {
                .references__suffix a {
                    font-weight: 500;
                    color: $general-link-color;
                    display: flex;
                    text-decoration-color: transparent;

                    &:not(:last-child):after {
                        content: ' ';
                        padding: 0;
                        width: toRem(1);
                        margin: 0 toRem(5);
                        background-color: $border-color;
                        position: relative;
                        top: 0;
                        bottom: 0;
                    }
                }

                .references__article-title {
                    font-weight: 500;
                    display: inline;
                }
            }
        }
    }

    &__navigation {
        .content-navigation {
            @include media('<tablet') {
                display: flex;
            }

            .btn {
                color: themed('journal');
                @include media('<tablet') {
                    flex: 1;
                    margin-left: toRem(4);
                    &:first-child {
                        margin-right: toRem(4);
                        margin-left: 0;
                    }
                }

                &:focus {
                    color: $lighter;
                }
            }
        }
    }

    &__sidebar {
        .contribs {
            section {
                display: none;
            }
        }

        .accordion-tabbed__tab {
            li:last-child {
                .teaser__item {
                    border-bottom: 0;
                    padding-bottom: 0;
                    .clinical-reflections_title {
                        color: $journal-color;
                        @include brand('color');
                        text-transform: uppercase;
                    }
                }
            }
        }

        .teaser {
            &__item {
                border-bottom: toRem(1) solid $border-color;
                margin-bottom: toRem(16);
                padding-bottom: toRem(16);

                &__title {
                    font-size: 0.875rem;
                    margin-bottom: toRem(8);
                }

                &__description {
                    margin: 0;
                    @include set-font-family();
                    font-size: 0.875rem;
                    color: $dark-secondary;
                }

                &__video {
                    margin-top: toRem(8);

                    i {
                        width: toRem(80);
                        height: toRem(80);
                    }
                }

                ul {
                    margin-top: 0;
                    font-size: 0.75rem;
                    color: $dark-gray;
                }
            }
        }
    }
}

.citation {
    &__top {
        margin-top: 0;
    }

    & &__title {
        margin-bottom: 0;
        line-height: inherit;

        @include media('<tablet') {
            font-size: 1.25rem;
            line-height: toRem(28);
        }
    }

    &__title {
        display: inline;
        vertical-align: middle;

        &__wrapper {
            display: flex;
            align-items: flex-end;
            margin: 0.5rem 0;
            justify-content: space-between;
            @include media('>tablet') {
                display: block;
            }
        }
    }

    &__doi {
        margin: 0;

        &__wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin: toRem(16) 0 0;
            @include media('>phone') {
                flex-direction: row;
                align-items: center;
            }
        }
    }

    &__subtitle {
        font-size: 1.75rem;
        line-height: inherit;
        @include media('<tablet') {
            font-size: 1.25rem;
            line-height: toRem(28);
        }
    }
}

.figure {
    &__title {
        color: $dark-secondary;
    }

    &__caption {
        font-weight: normal;
        color: $dark-gray;
        font-size: 0.875rem;
        @include set-font-family();
        line-height: toRem(22);

        p {
            font-size: inherit;
            font-family: inherit;
            line-height: inherit;
        }
    }

    &__image {
        margin-bottom: 0;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $body-bg;
            border: toRem(1) solid $border-color;
            margin-bottom: toRem(16);
        }
    }

    figure &-links {
        margin-top: toRem(16);
        font-weight: bold;
        font-size: 0.875rem;

        li {
            display: block;

            &:not(:last-of-type) {
                margin-bottom: toRem(16);

                &:after {
                    content: ' ';

                    .tab__pane & {
                        content: ' ';
                    }

                    width: toRem(1);
                    background-color: $border-color;
                    position: relative;
                    top: 0;
                    bottom: 0;
                    display: none;
                }
            }

            @include media('>phone') {
                display: inline-flex;
                &:not(:last-of-type) {
                    margin-bottom: 0;

                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
}

figure {
    border-bottom: toRem(1) solid $border-color;
    padding-bottom: toRem(16);

    .abstractInFull & {
        border-bottom: 0;
        padding-bottom: 0;
    }

    & + .article-section__title {
        border-top: 0;
        padding-top: toRem(8);
    }
}

.tab {
    &__pane {
        .cover-details {
            > div {
                padding: 0;
            }
        }

        .cover-details-image {
            img {
                border: toRem(4) solid $border-color;
            }

            position: relative;
        }
    }

    &-link {
        &:not(.primary-blue-color) {
            color: $general-link-color;
            font-weight: inherit;
        }
    }
}

.cover-details {
    p {
        margin: 0;
        font-size: 1rem;
        @include set-font-family();
        line-height: toRem(22);
    }

    .current-issue {
        align-items: center;
        flex-wrap: nowrap;
        margin: 0;

        figcaption {
            padding: 0;
        }

        &__cover {
            @include media('>=phone') {
                margin: 0;
            }
        }

        &__info {
            @include media('>tablet') {
                padding-left: toRem(16);
                margin: 0;
            }
        }

        &__details {
            text-align: left;
            padding-left: toRem(16);
            @include media('>phone') {
                padding: 0;
            }
        }

        &__specifics {
            font-size: 1rem;
            line-height: toRem(20);
            margin-bottom: toRem(4);
        }

        &__description {
            margin-bottom: 0;
        }

        img {
            height: auto;
            max-width: toRem(130);
            @include media('>tablet') {
                height: auto;
                max-width: toRem(124);
            }
        }
    }
}

.w-slide {
    &__content {
        .cover-details > div {
            padding: 0;
        }
    }
}

#secSuppl {
    font-size: 1.5rem;
}

.supplemental {
    &__items {
        list-style: none;
        padding: 0;
        font-size: 0.875rem;

        .supplemental__item {
            color: $dark-secondary;

            a {
                color: inherit;
                font-weight: bold;
            }
        }
    }

    &__item {
        &:not(:last-child) {
            border-bottom: toRem(1) solid $border-color;
            margin-bottom: toRem(16);
            padding-bottom: toRem(16);
        }

        &__title {
            margin: 0 0 toRem(8);
        }

        &__info {
            list-style: none;
            padding: 0;

            &__item {
                margin-bottom: toRem(8);

                .supplemental__item & a {
                    font-weight: 500;
                    color: $general-link-color;
                    text-decoration: underline;
                }

                span {
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.cme-link {
    visibility: visible;
    opacity: 1;
    transition: 0.25s opacity, 0.25s visible;

    &.hidden {
        visibility: hidden;
        opacity: 0;
    }

    display: flex;
    align-items: center;
    @include media('<tablet') {
        margin-top: toRem(24);
    }

    &__title {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: $journal-color;
        @include journal-branding('color');
    }

    .btn {
        margin-left: toRem(12);
        font-size: 0.625rem;
        padding: toRem(6) toRem(12);
    }
}

.NLM_verse {
    &-group {
        margin-bottom: 1rem;
        @include media('>=tablet') {
            font-size: 1.125rem;
            line-height: toRem(38);
        }
    }
}

.boxed-text-float {
    $this: &;
    padding: 1rem 1rem 0.5rem;
    background: $light-gray;

    .section__title {
        &:first-of-type {
            margin-top: 0;
            padding-top: 0;
            border-top: 0;
        }
    }

    &ednote {
        @extend #{$this};
    }
}

.orcid__wrapper {
    vertical-align: middle;

    i.icon-orcid {
        margin: 0 toRem(4) 0 0;
        line-height: toRem(1);
    }
}

.smallcaps {
    font-variant: small-caps;
}

.play_in_place {
    display: block;

    iframe,
    .video-js {
        width: 100%;
        min-height: toRem(240);
        @include media('>=tablet') {
            min-height: toRem(410);
        }
    }
    .video-js {
        margin-block-end: toRem(20);
        margin-block-start: toRem(20);
        button {
            background-color: $transparent-color;
            box-shadow: none;
            &:hover {
                filter: none;
            }
        }
    }
}

.disclosures-modal {
    &-toggle {
        color: $secondary;
        @include journal-branding('color');
        font-size: 0.75rem;
        font-weight: 500;
    }

    .modal {
        &__dialog {
            @include media('>=tablet') {
                min-width: toRem(600);
            }
            @include media('>=desktop') {
                min-width: toRem(730);
            }
            @include media('>=large') {
                min-width: $container-lg;
            }

            button {
                color: $dark-gray;
                background-color: transparent;
            }
        }

        &__body {
            @include set-font-family($noto-serif-font);
            font-weight: 300;

            @include media('>=tablet') {
                font-size: 1.25rem;
                line-height: toRem(38);
            }
        }

        &__header__title {
            color: $secondary;
            @include journal-branding('color');
        }

        .supplementary-material {
            display: inline;
        }
    }
}

.corrections {
    &:not(.titled_issues__title) {
        border: toRem(1) solid $border-color;
        border-radius: 0.625rem;
        margin-bottom: 0.75rem;
    }

    &__ctrl {
        display: flex;
        align-items: center;
        color: $dark-secondary;
        padding: toRem(8) toRem(12);
        border-radius: 0.625rem 0.625rem 0 0;

        &.js--open {
            background: $light;
            border-bottom: toRem(1) solid $border-color;
        }

        i {
            margin-right: 0.625rem;
        }
    }

    &__icon {
        color: $open-access-color;
    }

    &__label {
        font-size: 0.75rem;
        margin-right: 0.75rem;
        font-weight: bold;
    }

    .icon-section_arrow_d {
        font-size: 0.625rem;
    }

    &__body {
        padding: 1rem;
        margin: 0;

        &__item {
            &:not(:last-child) {
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: toRem(1) solid $light-gray;
            }
        }
    }

    &__title {
        margin-bottom: 0;
        margin-top: 0.5rem;

        article &:not(:last-child) {
            border-bottom: none;
            margin: 0.5rem 0 0;
            padding: 0;
        }
    }

    &__authors {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        color: $correction-authors-color;

        .loa {
            font-size: inherit;
            color: $correction-authors-color;
        }
    }

    .issue-item-access {
        color: $free-access-color;
        display: inline-block;
        margin-left: toRem(8);
        &--open-access {
            display: inline-block;
            margin-left: toRem(8);
            border: none;
            i {
                font-size: 1.25rem;
            }
        }
    }

    .citation {
        &__top__item {
            text-transform: capitalize;

            &:not(:last-child):after {
                color: $border-color;
                margin: 0 0.5rem;
            }
        }
    }
}
