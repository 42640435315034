.featured__topic {
    height: 100%;
    margin-top: toRem(16);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media('>phone') {
        margin-top: 0;
        align-items: flex-start;
    }

    &__right-side {
        flex: 1 0 100%;

        @include media('>phone') {
            flex: 0 1 toRem(300);
        }

        .advertisment {
            margin: toRem(24) 0;
            @include media('>phone') {
                margin: 0;
                min-height: toRem(250);
            }
        }
    }

    &__title {
        width: 100%;
    }

    .topic__card {
        height: toRem(148);
        flex: 1 0 100%;

        @include media('>phone') {
            flex: 1;
            margin-right: toRem(30);
            height: toRem(188);
        }
        @include media('>tablet') {
            height: toRem(250);
        }

        &__text {
            padding: toRem(10) toRem(12);
            font-size: 1rem;
        }
    }
}
