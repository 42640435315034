#citationAlerts {
    .alert__list {
        .serialTitle::after {
            content: ' ';
        }

        .comma::after {
            content: ' ';
        }
    }
}
