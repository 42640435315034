.comments {
    &__wrapper {
        margin-top: toRem(10);

        .btn {
            span {
                margin-left: toRem(8);
            }
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        h6,
        .btn {
            margin-bottom: 0.625rem;
        }
    }
}

.comment {
    padding: 1.5rem 0;
    border-top: toRem(1) solid $border-color;

    &:first-child {
        margin-top: 0.625rem;
    }
    &:last-child {
        border-bottom: toRem(1) solid $border-color;
    }

    @include media('<tablet') {
        padding: 0.625rem 0;
    }

    &__info {
        font-size: 0.75rem;
        color: $dark-gray;
        font-weight: 500;
        margin-bottom: toRem(16);

        *:not(:last-child) {
            &:after {
                content: '•';
                margin: 0 toRem(8);
                font-size: 1rem;
                color: $darker;
                font-weight: bold;
            }
        }

        a {
            color: $darker;
            font-weight: bold;
        }
    }

    &__title {
        margin-bottom: toRem(8);
    }

    &__description {
        font-size: 0.875rem;
        line-height: toRem(24);
    }

    &__modal {
        .modal {
            &__dialog {
                padding: 0;
                @include media('>=tablet') {
                    min-width: toRem(592);
                }
            }

            &__header {
                padding: 2rem 1rem 0 2rem;

                .close {
                    top: toRem(14);
                    right: toRem(14);
                    position: absolute;
                    color: $dark;
                    background-color: transparent;
                }

                h2 {
                    font-size: 1rem;
                    font-weight: bold;
                    text-align: left;
                    margin-bottom: 1rem;
                    @include media('>=tablet') {
                        font-size: 1.5rem;
                    }
                }

                &__info {
                    font-size: 0.75rem;
                    color: $dark;
                    font-weight: normal;
                    margin-bottom: 0;
                    @include media('>=tablet') {
                        font-size: 0.875rem;
                    }

                    a {
                        color: $link-secondary-color;
                    }
                }
            }

            &__body {
                padding: 1.5rem 2rem;

                label {
                    color: $dark-secondary;
                }

                input,
                textarea {
                    background-color: $lighter;
                }
            }

            &__actions {
                border-top: toRem(1) solid $border-color;
                padding: 1rem 1.375rem;
                text-align: right;

                .btn--inverse {
                    &:focus,
                    &:hover {
                        color: $lighter;
                    }
                }
            }

            &__message {
                .modal__action {
                    text-align: center;
                }
            }
        }

        .disclosures__wrapper {
            border-left: toRem(3) solid $border-color;
            padding-left: 1rem;
            overflow: hidden;
            transition: height 200ms ease-out;
            height: 0;

            .input-group {
                margin-bottom: 0;
            }

            &.active {
                height: toRem(230);
            }
        }

        .error {
            font-size: 0.625rem;
            &-message {
                padding: 1rem 3rem;
            }
        }

        .acp-loader {
            padding: 2rem 0;
        }

        .tox {
            border-radius: 1rem;
        }
    }
}
