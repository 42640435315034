.media__do {
    video {
        max-width: 100%;
    }

    .podcast {
        border-top: 0;
        border-bottom: 0;
        flex-wrap: wrap;

        &__player {
            flex: 1 1 100%;
        }

        &__actions {
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: 1rem;
        }

        .audio-player {
            margin: 0;
        }
    }
}
