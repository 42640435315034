.facets {
    @include media('<tablet') {
        &__wrapper {
            box-shadow: $card-box-shadow;
            padding: toRem(24) toRem(12);
            margin: toRem(24) toRem(16) toRem(42);

            .facet {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
