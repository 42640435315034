.pagination {
    margin-bottom: toRem(56);
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    justify-content: flex-start;

    &__list {
        flex: 0 1 auto;
        justify-content: flex-start;

        li {
            padding: 0;
            .active {
                background-color: $secondary;
                @include journal-branding('background-color');
            }

            a {
                height: auto;
                font-weight: 500;
                padding: toRem(4) toRem(9);
                margin: 0 toRem(2);
            }
        }
    }
    &__btn {
        padding: toRem(6);
    }
    .disabled {
        padding: toRem(6);
    }
}
