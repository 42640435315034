.activities {
    font-size: 0.875rem;

    &__list {
        margin-bottom: toRem(24);

        li ~ li {
            margin-top: toRem(8);
        }

        .active {
            color: $journal-color;
            @include journal-branding('color');
            font-weight: bold;
        }

        a:hover {
            color: $journal-color;
            @include journal-branding('color');
        }
    }

    &__filter {
        margin-bottom: toRem(36);
    }
}

.claim-section {
    font-size: 0.75rem;

    .activities & {
        @extend .card--bordered;
        margin: toRem(-17);
        margin-top: 0;
    }

    &__title {
        color: $claim-section-title-color;
        font-size: 0.875rem;
    }

    &__form {
        label {
            font-weight: 600;
            display: inline-block;
            margin-right: toRem(8);
        }

        input,
        select {
            max-width: fit-content;
            border-radius: 0;
            padding: toRem(6) toRem(8);
            background-color: transparent;
        }

        input {
            &[readonly] {
                padding: 0;
                border: none;
            }
        }

        select {
            height: toRem(27);
            padding-right: toRem(28);
            margin-right: toRem(12);
        }

        .block-label {
            label {
                display: block;
            }
        }

        .btn--inverse {
            margin: toRem(10) 0 toRem(20);
        }
    }
}

#abim {
    max-width: toRem(100);
}

.w-slide__content[original-target='.activities'] {
    padding: toRem(24);
}
