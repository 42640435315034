.topics {
    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: toRem(24);
        @include set-font-family($noto-serif-font);
        font-size: 1rem;
        font-weight: 300;
        color: $dark-secondary;
        @include media('>phone') {
            margin-bottom: toRem(32);
        }
        @include media('>tablet') {
            margin-bottom: toRem(40);
        }

        &__image {
            margin-bottom: toRem(8);
        }

        &__content {
            text-align: center;
            margin: 0;
        }
    }
}
