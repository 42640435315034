.social-menus {
    @include media('<tablet') {
        padding-bottom: toRem(24);
        box-shadow: $card-box-shadow;
    }
    @include media('<tablet') {
        padding-bottom: 0;
    }

    .page__social-links {
        @include media('<desktop') {
            padding: toRem(32) toRem(22) toRem(44);
        }

        @include media('<tablet') {
            padding-bottom: 0;
            padding-top: 0;
        }

        .card__title {
            margin-bottom: toRem(34);
            padding-left: toRem(4);
            @include media('>tablet') {
                margin-bottom: toRem(8);
            }
        }

        ul {
            justify-content: flex-start;

            @include media('<tablet') {
                justify-content: space-between;
            }
        }

        li {
            flex: 1;
        }
    }
}
