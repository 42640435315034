.w-slide {
    &_head {
        height: $slide-head-height;
        position: relative;
        font-size: 1rem;

        .w-slide__title.js--open {
            margin-left: toRem(16);
        }
    }
    &__back {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            top: 0;
            width: toRem(1);
            background-color: rgba($border-color, 0.2);
        }
    }
    &__content {
        padding: 0;

        & > ul > li {
            padding: toRem(16);
            color: $dark-secondary;

            a {
                font-weight: 500;
            }

            &:last-child {
                border-bottom: toRem(1) solid $border-color;
            }
        }
    }
}
