.padding-wrapper {
    padding-right: toRem(24);
    padding-left: toRem(24);
    @include media('<desktop', '>=tablet') {
        padding-right: toRem(20);
        padding-left: toRem(20);
    }

    &-tablet {
        @include media('<desktop', '>=tablet') {
            padding-right: toRem(24);
            padding-left: toRem(24);
        }
    }

    @include media('<tablet') {
        padding-right: 0;
        padding-left: 0;
    }
}

.dark {
    &--md {
        @include media('<desktop') {
            background-color: $body-dark-bg;
            border-top: toRem(1) solid $border-color;
        }
    }
}

.page__content {
    padding-top: toRem(24);
    padding-bottom: 0;
    margin-bottom: toRem(80);

    @include media('>tablet') {
        margin-bottom: toRem(110);
    }

    .landing-page & {
        margin-bottom: 0;

        @include media('>tablet') {
            padding-top: toRem(40);
        }
    }

    .latest-articles-page & {
        padding-top: toRem(16);

        @include media('>phone') {
            padding-top: toRem(24);
        }
        @include media('>tablet') {
            padding-top: toRem(32);
        }
    }
}

.content {
    .page__content {
        margin-bottom: toRem(32);
    }

    @include media('<desktop') {
        .page__content {
            padding: toRem(24) 0 0;
        }
        & > .container {
            width: 100%;
        }
    }
}

.section {
    &__wrapper {
        padding-top: toRem(24);

        @include media('>tablet') {
            padding-top: toRem(40);
        }

        section:not(:first-child) {
            margin-bottom: toRem(24);
            @include media('>tablet') {
                margin-bottom: toRem(40);
            }
        }
    }

    &__title {
        font-size: 1.5rem;
        color: var(--journal-color);
        @include journal-branding('color');

        @include media('<phone') {
            font-size: 1.125rem;
        }
    }
}

.link {
    color: $general-link-color;

    &:hover {
        color: $general-link-color;
        text-decoration: underline;
    }
}

.uppercase {
    text-transform: uppercase !important;
}

.branded {
    @include setBrandRule('color');

    &-bg {
        @include setBrandRule('background-color');
    }

    &--md {
        @include media('<large') {
            @include setBrandRule('color');
        }
    }
}

.border-top--sm {
    border-top: toRem(1) solid $border-color;
}

.exams-page {
    .card--bordered-top {
        border-top: toRem(1) solid $border-color;
        padding-top: toRem(24);

        @include media('>tablet') {
            padding-top: 0;
            border-top: 0;
        }
    }

    .card ~ .card--bordered-top {
        margin-top: toRem(24);
        @include media('>tablet') {
            margin-top: toRem(32);
        }
    }
}

.diamond-list {
    li {
        padding-left: 0.875rem;
        position: relative;
        margin-bottom: 1rem;

        &:before {
            content: ' ';
            width: toRem(6);
            height: toRem(6);
            transform: rotate(45deg);
            background-color: $dark;
            display: block;
            position: absolute;
            left: 0;
            top: 0.375rem;
        }
    }
}

b,
strong {
    font-weight: bold;
}

.error {
    font-size: 0.625rem;
    &-message {
        padding: 0.5rem;
        background-color: $danger;
        color: $lighter;
        font-weight: bold;
        border-radius: toRem(4);
        margin-bottom: 0.5rem;
    }
}

.royal-purple-color {
    color: $royal-purple;
}
