.latest-articles {
    margin-top: 0;

    .section-header {
        margin-bottom: 1rem;
    }

    &__body {
        margin-top: 0;

        > li {
            margin-bottom: 1.5rem;
            @include media('>=tablet') {
                margin-bottom: 2rem;
            }
        }
    }

    .issue-item {
        &__footer {
            @include media('>=tablet') {
                margin-top: 0.5rem;
            }
        }

        &__abstract {
            color: $dark-secondary;
            @include set-font-family($noto-serif-font);
            font-size: 0.75rem;
        }
    }

    .titled_issues__title {
        font-weight: bold;
    }

    .pagination {
        padding-top: 1rem;
        border-top: toRem(1) solid $border-color;
    }
}
