// Usage:
// @include font-face(Font Family name, '#{path}/font-name', font-weight, font-style ,'Font weight name','Font Family name-no spaces');
// ex:
// @include font-face(Open Sans, '#{$openSans}/open-sans-v15-latin-300', 300, normal,'Open Sans Light','OpenSans-Light');
@mixin font-face($font-family, $file-path, $font-weight, $font-style, $local1, $local2, $isInlineFont: false) {
    @font-face {
        font-family: #{$font-family};
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;

        @if ($isInlineFont) {
            src: url('#{$file-path}') format('woff'); /* Modern Browsers */
        } @else {
            src: local($local1), local($local2), url('#{$file-path}.woff2') format('woff2'),
                url('#{$file-path}.woff') format('woff'); /* Modern Browsers */
        }
    }
}
