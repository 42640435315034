.btn {
    @include media('<desktop') {
        padding-top: toRem(12);
        padding-bottom: toRem(12);
        border-radius: toRem(22);
    }

    &:focus {
        color: $lighter;
    }

    &--inverse {
        border-color: $border-color;
        color: $dark-secondary;

        .journal-branding & {
            @extend .btn--inverse;
            &:focus {
                color: $lighter;
                background-color: themed('journal');

                i {
                    color: inherit;
                }
            }
        }

        &:hover,
        &:focus {
            @include setBrandRule('background-color');
        }
    }

    &--wide {
        padding: 0.75rem 2.875rem;
        @include media('>tablet') {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    &--link {
        .journal-branding & {
            background-color: $btn-link-color;
            color: $lighter;

            span {
                margin: 0 toRem(8);
            }
        }
    }

    &--white {
        background-color: $white;
        color: $secondary;
        @include journal-branding('color');
        border: toRem(2) solid $border-color;

        .journal-branding & {
            background-color: $white;
        }

        &:hover {
            border: toRem(2) solid $secondary;
            @include journal-branding('border-color');
        }
    }
}
