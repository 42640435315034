.audio-player {
    display: flex;
    align-items: center;
    flex: 1;
    background-color: $audio-player-bg-color;
    border-radius: toRem(20);
    padding: toRem(8) toRem(12);
    margin: toRem(12) 0 toRem(14);

    @include media('>phone') {
        margin: 0 toRem(20) 0 toRem(24);
    }

    &__progress {
        order: 2;
        flex: 1;
    }

    &__control {
        &--left {
            order: 1;
            margin-right: toRem(8);
        }

        &--right {
            margin-left: toRem(8);
            order: 2;
            color: $audio-player-time-tracker-color;
        }
    }

    button {
        margin-left: 0;
        background-color: transparent;
        color: $audio-player-controls-color;
    }
}
