@mixin setBrandRule($rule: 'color') {
    $classes: (
        '.aim': $aim,
        '.ajc': $ajc,
        '.acr': $acr,
        '.aimcc': $aimcc,
    );
    #{$rule}: $journal-color;
    @each $class, $color in $classes {
        #{$class} & {
            #{$rule}: $color;
        }
    }
}
