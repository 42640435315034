.exam-disclosure {
    .show-exam {
        &__title {
            margin: toRem(24) 0;
            font-size: 1.5rem;
            @include media('>tablet') {
                margin: toRem(32) 0;
                font-size: 1.75rem;
            }
        }
    }
}
