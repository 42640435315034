.article {
    &__breadcrumbs {
        font-weight: 500;
        font-size: 0.875rem;
        margin-bottom: toRem(25);

        a + a:before {
            content: ' > ';
            color: $dark;
        }
        .article__tocHeading {
            &:last-child {
                @include journal-branding('color');
            }
        }
    }
}
