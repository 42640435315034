.exam-item {
    @include media('>=phone') {
        display: flex;
    }

    &__title {
        color: $exam-title-color;
        @include journal-branding('color');
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: toRem(8);
    }

    &__content {
        margin-right: toRem(26);
        flex: 3;
    }

    &__meta {
        @include media('>=phone') {
            flex: 1;
            text-align: center;
            align-self: center;
        }

        font-size: 0.75rem;
        color: $exam-meta-color;
    }

    &__info {
        font-size: 0.75rem;
        font-style: italic;
        margin-bottom: toRem(8);
    }

    &__link {
        .acp-loader {
            &:after {
                width: toRem(24);
                height: toRem(24);
                border-width: toRem(3);
            }
        }
        a {
            @extend .btn;
            @extend .btn--inverse;
            display: block;

            &:focus {
                color: $lighter;
                background-color: $secondary;
                @include journal-branding('background-color');
            }
            @include media('<phone') {
                text-align: center;
            }
        }
    }

    &__status,
    &__credit-hours {
        font-weight: 500;
        ~ .exam-item__link {
            margin-top: toRem(16);
        }

        @include media('>=phone') {
            display: block;
        }
    }

    &__status {
        .label {
            font-weight: 500;
            @include media('>=phone') {
                display: none;
            }
        }

        @include media('<phone') {
            ~ .exam-item__credit-hours {
                &:before {
                    content: ', ';
                }
            }
        }
    }
    &__expires-date {
        color: $exam-title-color;
        display: flex;
        padding: 0 toRem(16);
        & span {
            margin-left: toRem(3);
        }
    }
    .exam-item__title {
        a {
            i {
                font-weight: 500;
                font-size: toRem(14);
            }
        }
    }
}
