.exam-agreement {
    &__section {
        margin-top: toRem(24);
        font-size: 0.875rem;
        color: $exam-agreement-section-color;
        line-height: toRem(24);

        &-title {
            font-size: inherit;
            color: $exam-agreement-section-color;
        }
    }

    .rlist {
        li {
            margin-bottom: toRem(24);
        }
    }

    sup {
        margin: 0 toRem(4);
    }

    &__link {
        margin-top: toRem(32);
        margin-bottom: toRem(24);
    }

    &__info {
        &__title {
            margin-bottom: toRem(8);
        }

        &__text {
            @include set-font-family($noto-serif-font);
            font-weight: 500;
            color: $dark-secondary;
        }

        a {
            color: $general-link-color;
            text-decoration: underline;
        }
    }
}
