.card {
    &.advertisment {
        display: flex;
        justify-content: center;
    }

    &--bordered {
        border: toRem(1) solid $border-color;
        padding: toRem(16);
    }

    &--bordered-top {
        border-top: toRem(1) solid $border-color;
        font-size: 0.875rem;
    }

    &__title {
        font-weight: 700;
        text-transform: none;

        &--bordered {
            @extend .card__title;

            padding-bottom: toRem(8);
            border-bottom: toRem(1) solid $border-color;
        }
    }
}

.card--featured {
    display: flex;
    background: $light-gray;
    justify-content: space-between;
    margin-bottom: toRem(24);
    min-height: toRem(295);

    @include media('<tablet') {
        flex-direction: column-reverse;
        min-height: auto;
    }
    @include media('<desktop') {
        margin-top: 0;
        min-height: toRem(270);
    }

    .card-img {
        display: flex;
        align-items: start;
        justify-content: center;
        overflow: hidden;
        filter: grayscale(100%);
        transition: 0.25s filter;
        max-width: 100%;
        flex: 1 1 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        min-height: toRem(242);

        @include media('>=tablet') {
            width: toRem(350);
            min-height: auto;
        }

        @include media('>=desktop') {
            flex: 1;
        }
    }

    &--sm {
        min-height: toRem(212);
        @include media('<desktop') {
            min-height: auto;
        }
        .card-img {
            width: toRem(255);
            @include media('<desktop') {
                width: toRem(214);
                flex: 1 0 toRem(214);
                min-height: toRem(180);
            }
            @include media('<tablet') {
                display: none;
            }
        }
    }

    &:hover {
        .card-img {
            filter: grayscale(0);
        }
    }

    .card-content {
        flex: 1 0;
        padding: toRem(24) toRem(30) toRem(44);

        h5 {
            margin-bottom: 0;
        }
        h3 {
            font-size: toRem(18);
            margin-bottom: 0;
        }

        @include media('<desktop') {
            flex: 1 1 100%;
            padding: toRem(24) toRem(16) toRem(16);
        }
    }

    &.advertisment {
        padding: toRem(22) toRem(24);
        background-color: $light-gray;
        @include media('<desktop') {
            padding: 0;
            background-color: transparent;
            justify-content: flex-start;
            align-items: flex-start;
        }
        @include media('<tablet') {
            justify-content: center;
            align-items: center;
        }
    }

    .citation {
        &__top {
            &__item {
                display: inline-block;
                color: $card-citation-color;
            }
        }
    }
}

.card__list {
    background: $light-gray;
    padding: toRem(40) toRem(24) toRem(16);

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .card {
        background: $lighter;
        border: toRem(1) solid $border-color;
        padding: toRem(32) toRem(16) toRem(32) toRem(24);
        display: flex;
        margin-bottom: toRem(32);
        width: 100%;
        @include media('<desktop') {
            margin-top: auto;
        }

        &-body {
            padding-right: toRem(8);
        }

        &-text {
            font-size: 0.875rem;
        }

        ~ .card {
            margin-top: 0;
        }

        &__wrapper {
            display: flex;
        }

        &-title {
            margin-bottom: toRem(8);
        }

        &-img {
            flex: 1 0 auto;
        }

        .btn {
            margin-top: toRem(16);
        }
    }
}

.card--twitter {
    padding: toRem(16) toRem(12) 0;
    margin-bottom: toRem(24);

    .card {
        &__title {
            margin-bottom: 0;
        }

        &__content {
            padding-left: toRem(8);
        }
    }
}

.card--journal {
    @include media('<tablet') {
        flex-direction: column;
        background-color: transparent;
        justify-content: center;
        align-items: center;
    }

    .card {
        &-images {
            display: flex;
            width: toRem(254);
            position: relative;
            overflow: hidden;

            &__item {
                top: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: map-get($z-indexes, 'card-journal-card-images-item');
                display: flex;
            }
        }

        &-text {
            @include set-font-family($noto-serif-font);
            color: $dark-secondary;
            font-size: 0.875rem;
            margin-top: toRem(8);
            line-height: toRem(24);

            + .btn {
                margin-top: toRem(16);
            }
        }

        &-content {
            @include media('<desktop') {
                flex: 1;
                padding: toRem(24) toRem(24) toRem(20);
            }
            @include media('<tablet') {
                background-color: $light-gray;
                margin-top: toRem(-40);
                padding-top: toRem(64);
            }
        }
    }

    .branded-bg {
        min-height: toRem(244);
        width: toRem(188);
        z-index: map-get($z-indexes, 'card-journal-branded-bg');
        position: relative;
        padding: 0 toRem(18);
        justify-content: center;
        display: flex;
        flex-direction: column;
        box-shadow: toRem(6) 0 toRem(10) 0 rgba($darker, 0.3);

        img {
            margin-bottom: toRem(24);
        }

        .btn--inverse {
            color: $lighter;
            padding: toRem(8);

            &:hover,
            &:active {
                color: $darker;
                background-color: $lighter;
            }
        }
    }

    &__wrapper {
        margin: toRem(24) 0 toRem(48);
    }

    .btn--inverse {
        padding: toRem(12);
    }

    .subhead {
        font-size: 0.75rem;
        display: block;
    }
}
