.tab {
    $this: #{&};

    &--generic {
        .teaser__item {
            padding-top: 0;
        }

        #{$this}__pane {
            padding-top: 0;
        }

        #{$this}__nav {
            li {
                font-size: 0.875rem;

                &:before {
                    height: toRem(4);
                    border-radius: toRem(2);
                }
            }
        }
    }
}
