.cme-exam-activity {
    $this: &;

    &__header {
        width: 100%;
    }

    &__title {
        color: $secondary;
        border-bottom: toRem(2) solid $secondary;
        @include journal-branding(
            'color',
            (
                'border-color': var(--secondary-color),
            )
        );
    }

    &__group {
        &__prefix {
            align-items: center;
            font-size: 0.875rem;
            margin-bottom: toRem(8);
            @include media('>=phone') {
                display: flex;
            }
        }

        &__title {
            flex: 1;
            font-size: 1rem;
            font-weight: 700;
        }

        &__credits {
            font-weight: 600;
            @include media('>=phone') {
                margin-right: toRem(16);
            }

            @include media('<phone') {
                margin: toRem(8) 0;
            }

            .credit {
                color: $exam-text-color;
                @include journal-branding('color');
            }
        }
        &__credits,
        .accordion-tabbed__control .cme-exam-activity__link {
            @include media('<phone') {
                display: none;
            }
        }

        .accordion-tabbed__tab.js--open {
            .cme-exam-activity__group__credits,
            .accordion-tabbed__control .cme-exam-activity__link {
                @include media('<phone') {
                    display: block;
                }
            }
        }
    }

    &__list {
        margin-bottom: toRem(24);
    }

    .accordion-tabbed__control {
        position: relative;

        h3 {
            margin: 0;

            @include media('<phone') {
                width: calc(100% - #{toRem(30)});
            }
        }

        &:after {
            font-size: 1.5rem;
            color: $exam-text-color;
            @include journal-branding('color');
            right: 0;
            position: absolute;
            top: toRem(8);

            @include media('<phone') {
                font-size: 1rem;
                top: toRem(10);
            }
        }

        border-bottom: toRem(1) solid $borders;
        margin-bottom: toRem(16);
    }

    [data-accordion-vport='screen-xs'] {
        .accordion-tabbed__control {
            @include media('>=phone') {
                &:after {
                    display: none;
                    font-size: 0.75rem;
                }
            }
        }
    }

    &__item {
        border-bottom: toRem(1) solid $secondary-border-color;
        padding: toRem(15) 0;
    }

    &__items__prefix,
    &__group-items__prefix,
    .point-of-care__header {
        @extend .topical-index__itemsPrefix;

        span:first-child {
            margin-right: 0;
        }
    }

    &__items__prefix {
        font-size: 0.875rem;

        span {
            flex: 1;
            text-align: left;
            margin-right: toRem(10);

            &:nth-child(2) {
                flex: 2;
            }

            &:first-child {
                margin-right: toRem(10);
            }

            &.text-center {
                text-align: center;
            }
        }
    }

    &__link {
        @extend .exam-item__link;
    }

    .exam-item {
        padding-left: toRem(16);

        &__meta,
        &__complete-date,
        &__content {
            flex: 1;
            text-align: left;
            margin-right: toRem(10);
        }

        &__meta {
            &.text-center {
                @include media('>=phone') {
                    text-align: center;
                }
            }
        }

        &__content {
            flex: 2;
        }

        &__complete-date,
        &__meta {
            font-size: 0.875rem;
            @include media('<phone') {
                font-size: 0.75rem;
                margin: toRem(8) 0;

                .label {
                    font-weight: 500;
                    color: $dark-secondary;
                }
            }
            @include media('>=phone') {
                .label {
                    display: none;
                }
            }
        }

        &__link,
        &__meta {
            align-self: start;
        }

        .input-group {
            display: flex;
            margin-right: toRem(10);
        }
    }
}
