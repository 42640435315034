.institutional {
    &--select {
        .ms-ctn {
            font-size: toRem(12);
            .ms-sel-ctn {
                height: toRem(40);
                border: toRem(1) solid $dim;
                font-size: toRem(12);
                .ms-inv {
                    padding-top: toRem(9);
                }
            }
        }
    }
    &-heading--primary {
        text-transform: capitalize;
        color: $darker;
        text-align: center;
    }
}

.openAthens-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: toRem(35.5);
    border-bottom: $openAthens-bottom-border;
    padding-bottom: toRem(72);
    @include media('<desktop', '>=tablet') {
        border-bottom: none;
        padding-bottom: 0;
        margin-top: toRem(72);
    }
}
