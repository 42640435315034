.search__item {
    .issue-item {
        border-bottom: toRem(1) solid $border-color;
        padding-bottom: toRem(24);

        @include media('>phone') {
            padding-bottom: toRem(20);
        }

        &__footer {
            margin-top: toRem(32);
            @include media('>phone') {
                margin-top: toRem(28);
            }
        }
    }

    i {
        display: inline;
        font-size: inherit;
        vertical-align: baseline;
        padding: 0 toRem(2);
    }

    sup {
        margin-right: toRem(4);
    }
}

.search-result {
    $this: &;

    &__filter {
        @include media('<tablet') {
            box-shadow: $card-box-shadow--elevated;
            padding: toRem(18);
        }
    }
}

.topics-result {
    margin-top: 0;

    .search-result {
        $this: &;

        &__follow {
            margin-bottom: 0;
            @include media('>tablet') {
                margin-bottom: toRem(16);
            }
        }
    }

    .search__result {
        &--space {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    .issue-item {
        &__authors {
            a:not([class*='read']) {
                color: $darker-link-color;
            }
        }
    }
}
