//TODO: clean up for this part in ticket LIT-526126
.accordion {
    &-tabbed {
        &__tab {
            .loa-accordion & {
                &.js--open {
                    box-shadow: inset 0 toRem(-3) 0 $transparent-color;
                    a.js--open {
                        box-shadow: inset 0 toRem(-3) 0 $accordion-tabbed-border-color;
                    }
                }
            }
        }
    }
}
