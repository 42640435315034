.survey {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 1rem;

    &__date {
        margin-bottom: toRem(8);
    }

    &__top__content {
        a {
            color: $secondary;
            @include journal-branding('color');
        }
    }

    &__question {
        border-bottom: toRem(1) solid $border-color;
        padding: toRem(16) 0;

        &__title {
            color: $dark-secondary;
            font-size: 0.875rem;
        }

        &.note {
            border-bottom: 0;
        }
    }

    &__actions {
        margin: toRem(24) 0;
        @include media('<tablet') {
            button {
                margin-bottom: toRem(24);
            }
            button,
            a {
                width: 100%;
                display: flex;
                text-align: center;
                justify-content: center;
            }
        }
    }

    &__info {
        font-style: italic;
        font-size: 0.75rem;
    }

    &__section {
        display: none;

        &.active {
            display: block;
        }

        &__items {
            .loading & {
                display: none;
            }
        }
    }

    &-dialog {
        .modal {
            &__dialog {
                width: calc(100% - #{toRem(32)});
                height: auto;
                margin: 10vh auto 0;
                padding-bottom: 1rem;
                max-height: 90%;
                @include media('>phone') {
                    width: calc(100% - #{toRem(62)});
                }
                @include media('>tablet') {
                    max-width: toRem(920);
                }
            }

            &-backdrop.in {
                background-color: rgba($darker, 0.8);
            }

            .close {
                background-color: transparent;
            }
        }
    }

    button,
    a {
        font-weight: bold;
    }

    button:not(.btn--white):not(.close) {
        color: $lighter;
        margin-right: toRem(24);
    }

    a.btn--white {
        text-align: center;
        color: $secondary;
        @include journal-branding('color');
    }

    .text-center {
        .survey__top__content {
            border-bottom: toRem(1) solid $border-color;
        }
    }

    .title {
        color: $secondary;
        @include journal-branding('color');
        margin-bottom: toRem(24);
    }

    .note {
        color: $dark-secondary;

        .label-txt {
            flex: 1 0 auto;

            &:before {
                border-color: $dark;
            }
        }

        input:checked ~ span:before {
            border-color: $secondary;
            @include journal-branding('border-color');
        }

        p {
            color: $dark;
        }

        .title {
            margin-bottom: toRem(8);
        }
    }

    label {
        font-size: 0.875rem;
    }

    .label-txt {
        &:before {
            background-color: transparent;
            border-color: $border-color;
        }
    }

    .input-group {
        margin-bottom: 0;
    }
}
