.content-navigation {
    margin: 0;
    @include media('<desktop') {
        margin-bottom: toRem(40);
    }

    .btn {
        text-decoration: none;

        @include media('<tablet') {
            padding: toRem(12) toRem(16);
        }

        &:after,
        &:before {
            display: none;
        }

        .journal-branding & {
            color: $dark-secondary;

            &:hover {
                color: $lighter;
            }
        }
    }

    .icon-arrow_l {
        margin-right: toRem(14);
        @include media('<tablet') {
            margin-right: toRem(8);
        }
    }
    .icon-arrow_r {
        margin-left: toRem(14);
        @include media('<tablet') {
            margin-left: toRem(8);
        }
    }
}
