.author-info {
    &__accordion {
        .accordion-tabbed {
            &__tab {
                background-color: $light;
                margin-bottom: 1rem;
                border-radius: 0.25rem;

                &.js--open {
                    .accordion-tabbed__control {
                        &:after {
                            position: absolute;
                            content: ' ';
                            background: linear-gradient(180deg, rgba($dark-gray, 0.15) 0%, transparent 56.77%);
                            bottom: toRem(-16);
                            height: toRem(16);
                            right: 0;
                            left: 0;
                        }

                        i {
                            background-color: $light;
                            transform: rotate(180deg);
                            color: $darker;
                        }
                    }
                }
            }

            &__control {
                background-color: $lighter;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: toRem(1) solid $light-gray;
                padding: 0.5rem 0.5rem 0.5rem 1.5rem;
                border-radius: 0.25rem;

                i {
                    width: toRem(50);
                    height: toRem(50);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                h6 {
                    margin-bottom: 0;
                }
            }

            &__content {
                padding: 0.5rem 1.5rem 1rem;
                border: toRem(1) solid $light-gray;
                border-top: none;
                font-size: 0.875rem;
                color: $dark-secondary;

                a {
                    text-decoration: underline;
                    color: $general-link-color;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-top: 1.5rem;
                }
            }
        }

        tr {
            display: flex;
            align-items: flex-start;
        }

        th,
        td {
            flex: 1;

            h6 {
                margin-bottom: 0;
            }

            p {
                margin-top: 0;
            }
        }
    }

    &__text {
        font-size: 0.875rem;
        color: $dark-secondary;
    }

    &__videos {
        .card {
            padding: 0;
            margin-bottom: 2rem;

            &__content {
                padding: 1rem 1rem 1.5rem;
            }
        }

        &__list {
            &__wrapper {
                margin-bottom: 2.5rem;
                @include media('>=desktop') {
                    margin-bottom: 5rem;
                }

                .section-header {
                    margin-bottom: 1.5rem;

                    &__title {
                        font-size: 1.375rem;
                    }
                }
            }

            &__item {
                display: flex;
                margin-bottom: 1.5rem;

                .acp-video {
                    i {
                        width: toRem(80);
                        height: toRem(80);
                    }
                }

                &__info {
                    @include media('<tablet') {
                        border: toRem(1) solid $border-color;
                        padding: 1rem;
                    }
                }
            }
        }
    }
}
