.issue-item {
    $this: &;
    padding-bottom: toRem(8);
    margin-bottom: toRem(16);

    &__header {
        font-weight: 500;
    }

    &:last-child {
        border-bottom: none;
    }

    &--with__letters {
        border-bottom: none;
    }

    &__title {
        a,
        span,
        h5,
        h3 {
            @include media('>tablet') {
                display: inline;
            }
        }
        h3 {
            font-size: toRem(18);
        }

        @include media('<desktop') {
            display: flex;
            align-items: flex-end;
        }

        h5,
        h3 {
            margin-bottom: 0;
            margin-right: toRem(8);

            @include media('<large') {
                @include font-size(18);
            }
        }
    }

    &__authors,
    &_page-range {
        margin-top: toRem(8);

        li {
            font-weight: 500;
            display: inline;
            font-size: 0.75rem;
            color: $issue-item__authors-color;
        }

        a {
            font-size: inherit;
            color: $issue-item__authors-color;
        }

        [class*='read'] {
            font-size: 0.75rem;
            color: $issue-item__authors-color;

            .journal-branding & {
                color: $issue-item__authors-color;
            }
        }

        .read-more {
            .ellipsis {
                margin-right: 0;
            }
        }
    }

    &__footer {
        margin-top: toRem(6);
        line-height: toRem(22);

        .journal-branding & {
            color: $dark-secondary;
        }
    }

    &__abstract {
        @include set-font-family($noto-serif-font);
    }

    &-access {
        padding: toRem(4);
        border-radius: toRem(2);
        border: toRem(1) solid $border-color;
        text-transform: uppercase;
        font-size: 0.625rem;
        font-weight: bold;

        span {
            margin: auto;
        }
    }

    .free_access {
        color: $free-access-color;
    }

    &__letter {
        border: toRem(1) solid $border-color;
        padding: toRem(16);
        margin-top: toRem(8);

        &__title {
            margin-bottom: toRem(4);
            font-size: toRem(16);
            span {
                margin-left: toRem(6);
            }
        }

        &__authors {
            margin-top: 0;
            padding: 0 toRem(8);
        }
    }

    &__links.separator {
        > li:not(:last-of-type) {
            padding-right: toRem(14);
            margin-right: toRem(14);
        }
    }
}
