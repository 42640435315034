.related__content {
    $this: &;
    padding: toRem(24) toRem(14);

    &__title {
        @extend .card__title--bordered;
    }

    &__list {
        margin-top: toRem(16);

        li {
            font-size: 0.875rem;
            font-weight: 500;

            &:not(:last-child) {
                margin-bottom: toRem(8);
            }
        }
    }

    &--topics {
        @extend .card--bordered;
        padding: toRem(16);

        #{$this}__title {
            color: $journal-color;
            @include journal-branding('color');
        }
    }

    &__slide {
        background-color: $darker;
        color: $lighter;
        font-size: 0.875rem;
        margin-bottom: toRem(16);
        font-weight: 500;
        a {
            padding: toRem(22) toRem(16);
            display: block;
        }

        i {
            margin-right: toRem(8);
        }

        @include media('>phone') {
            font-size: 1rem;
            font-weight: 600;
            background-color: transparent;
            border-bottom: toRem(1) solid $border-color;
            color: $journal-color;
            @include journal-branding('color');
            a {
                padding: toRem(8) 0;
            }
        }
    }
}
