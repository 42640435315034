@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?pwquo1');
    src: url('fonts/icomoon.eot?pwquo1#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?pwquo1') format('truetype'), url('fonts/icomoon.woff?pwquo1') format('woff'),
        url('fonts/icomoon.svg?pwquo1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-flag:before {
    content: '\e953';
}
.icon-lock-half:before {
    content: '\e9084';
}
.icon-minus-circle-filled:before {
    content: '\e9083';
}
.icon-plus-circle-filled:before {
    content: '\e9082';
}
.icon-copyright:before {
    content: '\e9081';
}
