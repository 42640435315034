.acp-loader {
    justify-content: center;
    align-items: center;
    display: none;

    .loading & {
        display: flex;
    }

    &::after {
        content: '';
        width: toRem(50);
        height: toRem(50);
        background: transparent;
        border: toRem(5) solid $secondary-bg-color;
        @include ltr-rtl('border-right', toRem(5) solid $secondary);
        @include journal-branding('border-right-color');
        border-radius: 50%;
        transform: rotate(0deg);
        animation: spinning 0.5s infinite linear;
    }
}
