.annals-consult-guys::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-consultguys-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.annals-for-educators::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-foreducators-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}

.annals-for-hospitalists::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-forhospitalists-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}

.annals-fresh-look::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-freshlook-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.annals-graphic-medicine::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-graphicmedicine-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.annals-latest-highlights-Podcast::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-latest-highlights-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.annals-on-call::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-oncall-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.beyond-the-guidelines::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-beyondtheguidelines-icon.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.clinical-guidelines::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-clinicalguidelines-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.firearms-health-specialty-collection::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-firearmshsc-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.research-and-reporting-methods::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-researchrm-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.reviews::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-reviews-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.academia-and-the-profession::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-academia-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.acp-journal-club::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-acpjc-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.ad-libitum::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-adlibitum-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.editorials::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-editorials-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.ideas-and-opinions::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-ideasopinions-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.in-the-clinic::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-itc-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.letters::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-letters-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.medicine-and-public-issues::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-medpubissues-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.on-being-a-doctor::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-onbeingadoctor-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.on-being-a-patient::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-onbeingapatient-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.original-research::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-originalresearch-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.position-papers::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-positionpapers-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.web-exclusives::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-webexclusives-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.specialty-collections-topics::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-specialtycollections-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.story-slam::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-storyslam-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.conferences::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-conferences-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.corrections::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-corrections-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.history-of-medicine::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-historyofmedicine-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.in-the-balance::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-inthebalance-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.precision-medicine::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-precisionmedicine-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.retractions::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-retractions-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.special-articles::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-special article-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.summaries-for-patients::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-summariesforpatients-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}
.thanks-to-reviewers::before {
    content: '';
    background: url('/pb-assets/images/icons/New Icons 2020/annals-summariesforpatients-icon-4c.jpg');
    min-width: toRem(54);
    height: toRem(54);
}

.visual-clinical-guidelines::before {
    content: '';
    background: url('../../../images/annals-visualguidelines-icon-4c_v3.png');
    min-width: toRem(54);
    height: toRem(54);
    background-size: toRem(54);
}
