.branded-list {
    font-size: 0.875rem;
    color: $dark-secondary;

    &__item {
        border-bottom: toRem(1) solid $border-color;
        margin: 1rem 0 0;
        padding: 0 0 1rem;

        &.active {
            font-weight: bold;
            border-bottom: toRem(2) solid $secondary;
            @include journal-branding('border-bottom-color');
        }

        &:first-child {
            padding-top: 0.625rem;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
