.my-exams {
    &__wrapper {
        padding: toRem(24) toRem(16);

        h5 {
            color: $secondary;
            @include journal-branding('color');
            border-bottom: toRem(1) solid $border-color;
            padding-bottom: toRem(4);
        }
    }

    font-size: 0.875rem;
    color: $dark-secondary;
    font-weight: bold;

    a {
        display: flex;
        align-items: center;
        margin-top: toRem(24);
        color: $secondary;
        @include journal-branding('color');

        i {
            line-height: toRem(1);
            margin-left: toRem(8);
        }
    }

    &__slide {
        background-color: $dark-secondary;
        border-radius: toRem(4);
        text-align: center;
        color: $lighter;
        padding: toRem(16);
        font-size: 0.875rem;
        font-weight: 500;
        margin-bottom: toRem(24);

        @include media('>phone') {
            margin-bottom: toRem(32);
        }

        i {
            margin-left: toRem(8);
        }
    }

    .w-slide & {
        padding: toRem(32) toRem(24);
    }

    &__sign-in {
        strong {
            margin: 0 toRem(4);
        }
    }
}
