.footer {
    $this: &;
    background-color: $body-bg;
    margin-top: 0;

    .col-md-3 {
        padding: 0 toRem(24);
    }

    .back-to-top {
        i {
            color: $lighter;
        }
    }

    .col-md-9 {
        @include media('<desktop') {
            padding: 0 toRem(8);
            margin-top: toRem(20);
        }
        @include media('>phone') {
            margin-top: toRem(24);
            border-top: toRem(1) solid $border-color;
        }
        @include media('>tablet') {
            border-top: none;
            margin-top: toRem(5);
        }
    }

    &__wrapper {
        padding: toRem(32) toRem(4);
        border-top: toRem(4) solid $secondary;
    }

    &__top__content {
        @include media('<desktop') {
            margin: 0 toRem(-20);
        }
    }

    &__logo {
        @include media('<desktop') {
            float: none;
            text-align: center;
        }

        &__list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            @include media('>tablet') {
                flex-direction: column;
            }

            img {
                min-width: toRem(86);
                border-bottom: toRem(1) solid $border-color;
                padding-bottom: toRem(24);
                margin-bottom: toRem(12);

                @include media('>phone') {
                    border-bottom: 0;
                    padding-right: toRem(24);
                    border-right: toRem(1) solid $border-color;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                @include media('>tablet') {
                    margin-bottom: toRem(16);
                    padding-bottom: toRem(16);
                    border-right: 0;
                    border-bottom: toRem(1) solid $border-color;
                }
            }
        }

        &__wrapper {
            padding-left: toRem(24);
            padding-bottom: toRem(16);
            border-bottom: toRem(1) solid $border-color;
            display: flex;
            align-items: center;
            margin-bottom: toRem(-10);

            p {
                text-align: left;
                font-style: italic;
                color: $darker;
                font-size: 0.625rem;
                font-weight: 500;
                padding: 0 0 0 toRem(20);
            }

            @include media('>phone') {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            @include media('>tablet') {
                padding-bottom: toRem(16);
                border-right: 0;
                border-bottom: toRem(1) solid $border-color;
                width: 100%;
            }

            img {
                padding: 0;
                margin: auto;
                border-bottom: 0;
                border-right: 0;
            }
        }
    }

    &__social-media {
        text-align: center;
        color: $dark;
        margin-top: toRem(24);

        @include media('<desktop') {
            float: none;
            text-align: center;
        }
        @include media('<tablet') {
            padding-bottom: toRem(20);
            margin: toRem(24) toRem(-8) 0;
            border-bottom: toRem(1) solid $border-color;
        }

        li {
            a {
                border-color: $border-color;
                width: toRem(30);
                height: toRem(30);
            }
        }

        i {
            font-size: 1rem;
        }
    }

    .accordion-tabbed {
        display: flex;
        flex-wrap: wrap;

        &__tab {
            width: 100%;
        }

        &__control {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &[aria-expanded='true'] {
                .accordion__icon {
                    &:before {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        @include media('<desktop') {
            .accordion__icon {
                position: relative;
                right: 0;
                border-left: toRem(1) solid $border-color;
                padding: toRem(14);

                &:after,
                &:before {
                    transition: all 0.2s;
                    position: absolute;
                    content: '';
                    background-color: $dark;
                    width: toRem(3);
                    height: toRem(18);
                    right: toRem(8);
                    top: toRem(4);
                }

                &:after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    h6 {
        font-size: 0.875rem;
        overflow: hidden;
        @include media('>=desktop') {
            &:not(#{$this}__header) {
                font-size: 1rem;
            }
        }
    }

    &__header {
        padding: 0 toRem(4);
        @include media('<desktop') {
            padding: 0 toRem(20);
            margin: toRem(28) 0;
        }
    }

    &__header.branded {
        color: $secondary;
    }

    &__headline {
        margin-bottom: 0;
        @include media('>=desktop') {
            margin-bottom: toRem(8);
        }

        #{$this}__subhead {
            margin-bottom: 0;
            font-weight: bold;
        }
    }

    &__subhead {
        display: block;
        font-weight: 500;
        margin-bottom: toRem(16);
        font-size: 0.75rem;
        @include media('>=desktop') {
            margin-bottom: toRem(19);
        }
    }

    &__bottom {
        margin-top: toRem(24);

        .secondary-container {
            padding: 0 toRem(20);
        }

        .copyright-up {
            width: auto;
            position: static;
            text-align: left;
            font-weight: 500;

            @include media('>=desktop') {
                text-align: right;
            }
        }

        .terms {
            font-size: 0.75rem;
            margin-top: toRem(16);
            text-transform: uppercase;
            display: flex;
            font-weight: bold;
            align-items: flex-start;
            justify-content: flex-start;

            @include media('>=desktop') {
                justify-content: flex-end;
                margin-top: toRem(8);
            }

            a {
                &:first-child {
                    margin-right: toRem(16);
                }
            }
        }
    }

    .fill-height {
        flex-grow: 3;

        &.col-md-9 {
            height: 100%;
        }
    }
}

.sitemap {
    @include media('<desktop') {
        margin: 0;
        padding: 0;

        .rlist {
            border-top: toRem(1) solid $border-color;
            margin-top: 1rem;
            padding-top: 1rem;
            columns: 1;
        }
    }

    &__column {
        height: 100%;
        border: none;
        padding: 0 toRem(4);
        display: flex;
        flex-direction: column;
    }

    &__data {
        width: 100%;
        padding: toRem(16);
        margin-top: toRem(8);
        background-color: $footer-secondary;
        display: flex;
        align-items: flex-start;
        font-weight: 500;
        @include media('>=desktop') {
            padding-bottom: toRem(24);
            flex: 1 1 auto;

            &:not(.mt-md-1) {
                margin-top: 0;
            }
        }
        li {
            a {
                padding: toRem(6) 0;
            }
        }
    }

    .fill-height {
        @include media('>=desktop') {
            padding-bottom: toRem(24);
        }
    }
}
