@import './colors-variables';

$noto-serif-font: 'Noto Serif JP', serif;
$preload-noto-serif-font: 'Sans Serif', serif;

$header-height: toRem(184);
$header-height-md: toRem(184);
$header-height-sm: toRem(88);

$section-nav-black-bg-break-point: desktop;
//publication-content
$article-body-border-top: none;

// z-index
$z-indexes: (
    'article-table-content-bg': -1,
    'article-table-content-tableFooter': 1,
    'article-table-content-tbody-tr': 2,
    'article-table-content-table': 1,
    'article-table-content-table-body': 3,
    'current-issue-title': 1,
    'card-journal-card-images-item': 1,
    'card-journal-branded-bg': 2,
    'acp-video-play': 2,
);

// Advanced Search
$advanced-search-searchIn-inputs-width-md: 70%;
$advanced-search-in-select-width: toRem(145);
$advanced-search-month-range-w: toRem(145);
$advanced-search-year-range-w: toRem(100);
$advanced-search-input-group-mb-md: toRem(25);

//slide
$slide-head-height: toRem(56);
