.figure-viewer,
.table-viewer {
    figure {
        border-bottom: 0;
    }

    &__reg__top {
        background: $darker;
    }

    &__title {
        font-size: 0.875rem;
        margin-bottom: toRem(8);

        &__text {
            margin-top: toRem(24);
        }
    }

    @include media('>=desktop') {
        &__ctrl__caption {
            display: flex;
            align-items: center;

            &:focus {
                text-decoration: none;
            }

            &:not(.js-open) {
                padding: toRem(14) toRem(18);
                border-radius: toRem(26);
            }

            &.js-open {
                background-color: transparent;
            }
        }
        &__caption__label {
            &:after {
                opacity: 0.5;
            }
        }
    }

    .figure__caption {
        color: $lighter;
    }

    .figure-links {
        font-weight: bold;
        font-size: 0.875rem;

        li {
            display: block;

            &:not(:last-of-type) {
                margin-bottom: toRem(16);

                &:after {
                    content: ' ';
                    width: toRem(1);
                    background-color: $border-color;
                    position: relative;
                    top: 0;
                    bottom: 0;
                    display: none;
                }
            }

            @include media('>phone') {
                display: inline-flex;

                &:not(:last-of-type) {
                    margin-bottom: 0;

                    &:after {
                        display: block;
                        margin-left: toRem(6);
                        margin-right: toRem(6);
                    }
                }
            }
        }
    }

    &__cent__right {
        background-color: $dark-secondary;
    }
}
