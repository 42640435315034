%border {
    background-color: $light-gray;
    height: toRem(6);
    border-radius: toRem(4);
    display: block;
}

.section-header {
    display: flex;
    align-items: center;

    &__img {
        height: toRem(65);
        width: toRem(65);
        flex-basis: toRem(65);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: toRem(8);
        background-color: $light;
        border: toRem(1) solid $light-gray;
        border-radius: toRem(4);
        overflow: hidden;
        margin-right: toRem(16);
    }

    &__title {
        color: $dark-secondary;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex: 1;

        &:after {
            @extend %border;
            content: ' ';
            margin-left: toRem(16);
            flex: 1;

            @include media('<tablet') {
                content: none;
            }
        }
    }

    &:after {
        @extend %border;
        margin-top: toRem(8);
        width: 100%;
    }

    @include media('<tablet') {
        flex-wrap: wrap;

        &__title {
            font-size: 1.375rem;

            &:after {
                content: none;
            }
        }

        &:after {
            content: ' ';
            flex: 1 1 100%;
        }
    }
}
