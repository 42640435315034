.hidden {
    &-l {
        @include media('>=desktop', '<1200px') {
            display: none !important; // to have the top priority, same as in the theme, and core level
        }
    }
    &-lx {
        @include media('>=1200px') {
            display: none !important; // to have the top priority, same as in the theme and core level
        }
    }
}
