.account-tab__nav {
    &.tab__nav {
        li {
            a {
                @include font-size(16);
                border: 0;
                border-bottom: toRem(4) solid transparent;
                @include media('<tablet') {
                    @include font-size(14);
                }
            }

            &:last-child {
                a {
                    border-right: 0;
                }
            }

            &.active {
                a {
                    border: 0;
                    border-bottom: toRem(4) solid $journal-color;
                }
            }
        }
    }
}

.profileMain {
    margin-bottom: toRem(24);
}
