.quick-search {
    $this: &;

    &__links-wrapper {
        border: toRem(1) solid $border-color;
        border-top-style: none;
    }

    .quick-menu {
        &__item {
            i {
                color: $link-secondary-color;
                @include journal-branding('color');
            }
            @include media('>=desktop') {
                display: flex;
            }
            @include media('>=large') {
                display: none;
            }
            @include media('<large') {
                padding: toRem(8) toRem(12);
                border-left: toRem(1) solid $quick-search-menu-border;
            }
        }
    }

    &__dropBlock {
        @include media('<large') {
            top: toRem(108);
        }
        @include media('<tablet') {
            top: toRem(86);
        }
    }

    .tab {
        @include media('<desktop') {
            #{$this}__searchbox.no-dropdown {
                input.auto-complete {
                    border-radius: 1rem;
                }
            }

            #{$this}__button {
                margin-left: toRem(16);
                padding: toRem(8) toRem(12);
            }
        }
    }

    &--all {
        background: transparent;
        padding: 0;
        margin: 0 0 toRem(30);
        box-shadow: none;
    }

    .autoComplete {
        &__item {
            margin-top: 0 !important; // I use important here to overwrite the spacing "mr-2" class which is on the generated autoComplete list items
            margin-bottom: 0 !important;
        }
        &.rlist {
            .autoComplete__text {
                float: none;
            }
        }
        &__link {
            &--grid {
                display: flex;
            }
        }
    }
}

.autoComplete_wrapper {
    .autoComplete {
        &__item {
            color: $autoComplete-list-item-text-color;
            .single_highlight_class {
                background: initial;
                font-weight: 700;
                color: $black;
            }
        }
    }
}
