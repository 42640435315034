.acp-video {
    position: relative;
    display: flex;

    &__play {
        z-index: map-get($z-indexes, 'acp-video-play');
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.875rem;
        color: $lighter;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        text-decoration: none;
        transition: 0.25s all;

        .playing & {
            visibility: hidden;
        }

        i {
            width: toRem(120);
            height: toRem(120);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.25s all;
            background-color: rgba($dark-secondary, 0.4);
            border-radius: 50%;
        }

        &:hover,
        &:focus {
            color: $lighter;
            text-decoration: none;

            i {
                background-color: $dark-secondary;
            }
        }
    }

    video {
        flex: 1;
        width: 100%;
    }

    &--grayscale {
        &:hover {
            video {
                filter: none;
            }
        }

        video {
            filter: grayscale(100%);
        }
    }
}
